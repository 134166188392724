import styled from "styled-components";

const sliderdiv = styled.section`
  display: flex;
  /* max-width: 80%; */
  margin: 40px auto;
  align-items: center;
  background-color: #f5f5f5cc;
  justify-content: space-evenly;
  color: black;
  padding: 80px 7.5rem;

  @media screen and (max-width: 420px) {
    /* font-size: 12px; */
    padding: 0;
  }

  .slider-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media screen and (max-width: 420px) {
      flex-direction: column;
      /* margin-top: 20px; */
    }
  }
  .main {
    position: relative;
  }

  .img-background {
    position: relative; /* Change to relative positioning */
    background-color: #eeeeee;
    height: 235px;
    width: 235px;
    padding: 10px;
    z-index: 2; /* Ensure the img-background appears below the outer-background */
    box-shadow: 0px 0px 5px 0px #00000080;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > h5 {
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      margin-top: 2px;
      margin-bottom: -4px;
    }
    @media screen and (max-width: 420px) {
      height: 170px;
      width: 170px;

      > h5 {
        font-size: 12px;
      }
    }
  }

  .right-img {
    /* width: 80%; */
    margin-top: 20px;
    height: 120px;
    width: 120px;
    @media screen and (max-width: 420px) {
      width: 50%;
      margin-top: 20px;
    }
  }

  .des {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;

    text-align: center;
    @media screen and (max-width: 420px) {
      > p {
        padding-bottom: 5px;
        font-size: 8px;
      }
    }
  }
  .outer-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 320px;
    width: 130px;
    border-radius: 15px;
    background: #03baed;
    z-index: 1; /* Ensure the outer-background appears above the img-background */
    @media screen and (max-width: 420px) {
      height: 220px;
      width: 100px;
    }
  }

  .text {
    margin-top: 10px;
    width: 60%;
    position: relative;
    /* margin-right: 20px; */
    /* margin-left: 90px; */
    text-align: left;

    h2 {
      font-size: 24px;
      font-weight: 500;
      /* line-height: 48px; */
      letter-spacing: 0em;
      text-align: left;
      margin-top: 0;
      margin-bottom: 0;
      margin-left: -50px;
      @media screen and (max-width: 420px) {
        font-size: 24px;
        padding: 0;
        text-align: center;
        margin: 0;
      }
    }
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      margin: 4px;
      @media screen and (max-width: 420px) {
        margin-top: 30px;
      }
      > span a {
        color: black;
        font-weight: 500;
      }
    }

    h3 {
      font-size: 32px;
    }
    h4 {
      font-size: 24px;
    }
    @media screen and (max-width: 768px) {
      width: 90%;
      > h1 {
        font-size: 26px;
      }
      > p {
        font-size: 12px;
      }
      margin-left: 0%;
    }
    .quotes-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .upper-quotes {
      position: absolute;
      top: 1;
      left: -40px; /* Adjust this value to move the upper quotes more to the left */
      z-index: 1; /* Ensure the upper quotes appear above the content */
      @media screen and (max-width: 420px) {
        left: -10px;
      }
    }

    .lower-quotes {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .stars {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hr {
    margin-top: 30px;
    width: 70%;
    height: 5px;
    background-color: #03baed;
    margin-left: -9rem;
    /* margin: 20px 0; */
  }
`;

export default sliderdiv;
