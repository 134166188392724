import styled from 'styled-components';

const PlansWrapper = styled.section`
  display: flex;
  max-width: 93%;
  margin: 1px auto 60px;
  padding: 0;

  .starter {
    border: 3px solid black;
    /* box-shadow: 5px 6px #f58634; */
  }

  .plan-info {
    position: relative; /* Add this line */
  }

  .popular-label {
    position: absolute;
    top: -3px; /* Adjust top position as needed */
    right: -2px; /* Adjust left position as needed */
    background-color: #03baed;
    color: white;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 10px;
  }

  .power-up-label {
    display: inline-block;
    background-color: #03baed;
    color: white;
    font-weight: bold;

    padding: 4px 12px; /* Adjust padding as needed */
    border-radius: 35px; /* Adjust border-radius for rounded corners */
    font-size: 0.6em; /* Adjust font size using relative units */
    margin-left: 18px; /* Adjust margin-left as needed */
    margin-top: -15px;
    margin-bottom: 8px;
    overflow: hidden; /* Hide any overflow content */
    white-space: nowrap; /* Prevent the text from wrapping */
    /* text-overflow: ellipsis; */
  }

  .paragraph-content {
    font-size: 15px;

    margin: 0;
    height: 50px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: inline-block;
  }

  .info-container {
    position: relative;
    display: inline-block;
  }

  .info-icon {
    margin-left: 2px; /* Add some space between the text and the info icon */
    cursor: pointer; /* Change cursor to pointer on hover */
    width: 12px;
    @media screen and (max-width: 435px) {
      width: 8px;
    }
  }

  .info-tooltip {
    position: absolute;
    font-size: 12px;
    top: 35px; /* Adjust the position of the tooltip */
    left: 0;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    display: none;
    width: 250px;
    z-index: 9999;
  }

  .info-icon:hover + .info-tooltip {
    display: block;
  }

  .info-tooltip-for-header {
    position: absolute;
    font-size: 12px;
    top: 50px; /* Adjust the position of the tooltip */
    left: 0;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    display: none;
    width: 250px;
    z-index: 9999;
  }

  .info-icon:hover + .info-tooltip-for-header {
    display: block;
  }

  .info-tooltip-for-section {
    position: absolute;
    font-size: 12px;
    top: 50px; /* Adjust the position of the tooltip */
    left: 0;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    display: none;
    width: 250px;
    z-index: 9999;
  }

  .info-icon:hover + .info-tooltip-for-section {
    display: block;
  }

  .feature-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start of the container */
  }

  .feature-list p {
    text-align: left; /* Align text to the left */
  }

  .fa-circle-xmark {
    font-size: 25px;
    color: red;
  }
  .fa-circle-check {
    font-size: 25px;
    color: green;
  }

  .header-p {
    position: relative;
    font-size: 13px;
    height: 35px;
    /* display: inline-block; */
    @media screen and (max-width: 435px) {
      font-size: 8px;
      height: 30px;
    }
  }

  .planPlus {
    padding-top: 5px;
    text-align: center;
    height: 35px;
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    @media screen and (max-width: 435px) {
      font-size: 8px;
    }
  }

  .sub-font {
    font-size: 12px;
    /* font-weight: 600; */
    /* margin-left: 2px; */
    @media screen and (max-width: 435px) {
      font-size: 8px;
    }
  }

  .sub-font-head {
    font-size: 12px;
    font-weight: 600;
    /* margin-left: 2px; */
    @media screen and (max-width: 435px) {
      font-size: 8px;
    }
  }

  .plan-mobile {
    display: none;

    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 25px;
      column-gap: 20px;
      justify-content: flex-start;
    }

    @media screen and (max-width: 576px) {
      column-gap: 10px;
    }
  }

  .plan-desktop {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      /* display: none; */
      width: 100%;
      overflow-y: auto;
    }
  }
`;

export const PlanSwitchBox = styled.div`
  width: 36%;
  @media screen and (max-width: 600px) {
    width: 32%;
  }

  .switchbox-info {
    height: 170px;
    display: flex;
    row-gap: 20px;
    flex-direction: column;
    align-items: center;
  }
`;

export const PlanBox = styled.div`
  width: 25%;
  padding: 10px;
  border-radius: 10px;
  /* border: 2px solid lightgray; */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  /* height: 54.5%; */

  @media screen and (max-width: 600px) {
    width: 30%;
  }
  @media screen and (max-width: 420px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  .xross-tick {
    height: 20px;
    margin-right: 5px;
    margin-bottom: -4px;
    @media screen and (max-width: 576px) {
      height: 10px;
      margin-bottom: 0;
    }
    @media screen and (max-width: 435px) {
      height: 10px;
      margin-bottom: 0;
    }
  }

  .strikethrough {
    display: block;
    color: darkgray;
    text-decoration: line-through;
  }

  .Heading-content {
    display: flex;
    /* text-align: center; */
    align-items: center;
  }

  .show-details-btn {
    color: black;
    background-color: #efefef;
    border: none;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 9px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;

    @media screen and (max-width: 435px) {
      padding: 10px 10px;
    }
  }

  .show-details-btn:hover {
    color: white;
    background-color: #03baed;

    border: none;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 9px;
    /* cursor: all-scroll; */
  }

  .plan-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 180px;

    > p h3 {
      text-align: center;
      font-size: 32px;
      @media screen and (max-width: 1200px) {
        font-size: 18px;
      }
      @media screen and (max-width: 576px) {
        /* height: 55px; */
      }
      @media screen and (max-width: 435px) {
        font-size: 16px;
      }
    }
    @media screen and (max-width: 435px) {
      height: 120px;
    }
  }

  h2 {
    font-size: 65px;
    @media screen and (max-width: 1200px) {
      font-size: 27px;
    }

    @media screen and (max-width: 576px) {
      font-size: 20px;
    }

    @media screen and (max-width: 435px) {
      font-size: 16px;
    }
  }

  h2,
  h3 {
    margin: 0;
  }

  small {
    text-align: left;
    color: black;
    margin-left: 4px;

    @media screen and (max-width: 576px) {
      font-size: 12px;
    }

    @media screen and (max-width: 435px) {
      font-size: 10px;
    }
  }

  .header-p {
    font-size: 13px;
    height: 35px;
    /* display: inline-block; */
    @media screen and (max-width: 435px) {
      font-size: 8px;
      height: 30px;

      /* display: inline-block; */
    }
  }
  /* 
  .header-p p {
    display: inline-block;
  } */

  p {
    font-size: 15px;
    margin: 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    /* border-bottom: 1px solid black; */

    @media screen and (max-width: 576px) {
      /* height: 10px; */
      font-size: 10px;
    }
    @media screen and (max-width: 435px) {
      /* height: 10px; */
      margin-bottom: 0;
    }
  }
  .custom-hr {
    height: 1px; /* Adjust the height of the line */
    width: 100%; /* Set the width to 100% to make it span the container */
    background-color: #cecece;
    /* Set the color of the line */
    opacity: 80%;
    margin: 10px 0; /* Add margin for spacing */
  }

  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    width: 100%;
    height: 56px;
    cursor: pointer;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 435px) {
      font-size: 12px;
    }
  }

  .demo-btn {
    background-color: #f58634;
    :hover {
      background-color: #86bc4c;
      color: black;
    }
  }

  > div:nth-child(3) {
    @media screen and (max-width: 500px) {
      font-size: 12px;
      text-align: left !important;
    }
  }
`;
export const PlanBox2 = styled.div`
  width: 25%;
  padding: 10px;
  border-radius: 10px;
  /* border: 2px solid lightgray; */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

  /* height: 58.7%; */

  @media screen and (max-width: 600px) {
    width: 30%;
  }
  @media screen and (max-width: 420px) {
    padding-left: 5px;
    padding-right: 5px;
  }
  .xross-tick {
    height: 20px;
    margin-right: 5px;
    margin-bottom: -4px;
    @media screen and (max-width: 576px) {
      height: 10px;
      margin-bottom: 0;
    }
    @media screen and (max-width: 435px) {
      height: 10px;
      margin-bottom: 0;
    }
  }

  .custom-hr {
    height: 1px; /* Adjust the height of the line */
    width: 100%; /* Set the width to 100% to make it span the container */
    background-color: #cecece;
    /* Set the color of the line */
    opacity: 80%;
    margin: 10px 0; /* Add margin for spacing */
  }

  .strikethrough {
    display: block;
    color: darkgray;
    text-decoration: line-through;
  }

  .Heading-content {
    display: flex;
    /* text-align: center; */
    align-items: center;
  }

  .show-details-btn {
    color: black;
    background-color: #efefef;
    border: none;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 9px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;

    @media screen and (max-width: 435px) {
      padding: 10px 10px;
    }
  }

  .show-details-btn:hover {
    color: white;
    background-color: #03baed;

    border: none;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 9px;
    /* cursor: all-scroll; */
  }

  .plan-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 180px;

    > p h3 {
      text-align: center;
      font-size: 32px;
      @media screen and (max-width: 1200px) {
        font-size: 18px;
      }
      @media screen and (max-width: 576px) {
        /* height: 55px; */
      }
      @media screen and (max-width: 435px) {
        font-size: 16px;
      }
    }
    @media screen and (max-width: 435px) {
      height: 120px;
    }
  }

  h2 {
    font-size: 65px;
    @media screen and (max-width: 1200px) {
      font-size: 27px;
    }

    @media screen and (max-width: 576px) {
      font-size: 20px;
    }

    @media screen and (max-width: 435px) {
      font-size: 16px;
    }
  }

  h2,
  h3 {
    margin: 0;
  }

  small {
    text-align: left;
    color: black;
    margin-left: 4px;

    @media screen and (max-width: 576px) {
      font-size: 12px;
    }

    @media screen and (max-width: 435px) {
      font-size: 10px;
    }
  }

  p {
    font-size: 15px;
    margin: 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    /* border-bottom: 1px solid black; */

    @media screen and (max-width: 576px) {
      /* height: 10px; */
      font-size: 10px;
    }
    @media screen and (max-width: 435px) {
      /* height: 10px; */
      margin-bottom: 0;
    }
  }

  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    width: 100%;
    height: 56px;
    cursor: pointer;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 435px) {
      font-size: 12px;
    }
  }

  .demo-btn {
    background-color: #f58634;
    :hover {
      background-color: #86bc4c;
      color: black;
    }
  }

  > div:nth-child(3) {
    @media screen and (max-width: 500px) {
      font-size: 12px;
      text-align: left !important;
    }
  }
`;

export const PlanBox3 = styled.div`
  width: 25%;
  padding: 10px;
  border-radius: 10px;
  /* border: 2px solid lightgray; */
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

  /* height: 93.5%; */

  @media screen and (max-width: 600px) {
    width: 30%;
  }
  @media screen and (max-width: 420px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  .custom-hr-with-black {
    height: 1px; /* Adjust the height of the line */
    width: 100%; /* Set the width to 100% to make it span the container */
    background-color: black;
    /* Set the color of the line */
    opacity: 80%;
    margin: 10px 0; /* Add margin for spacing */
  }

  .custom-hr {
  }

  .xross-tick {
    height: 20px;
    margin-right: 5px;
    margin-bottom: -4px;
    @media screen and (max-width: 576px) {
      height: 10px;
      margin-bottom: 0;
    }
    @media screen and (max-width: 435px) {
      height: 10px;
      margin-bottom: 0;
    }
  }

  .star-icon {
    /* height: 9px; */
    width: 10px;
    margin-bottom: -1px;
    margin-right: 2px;
    @media screen and (max-width: 576px) {
      height: 10px;
    }
    @media screen and (max-width: 435px) {
      height: 10px;
    }
  }
  .strikethrough {
    display: block;
    color: darkgray;
    text-decoration: line-through;
  }

  .Heading-content {
    display: flex;
    /* text-align: center; */
    align-items: center;
  }

  .show-details-btn {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 9px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    margin-top: -2px;

    @media screen and (max-width: 435px) {
      padding: 10px 10px;
    }
  }

  .show-details-btn:hover {
    color: white;
    background-color: #03baed;

    border: none;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 9px;

    /* cursor: all-scroll; */
  }

  .plan-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 180px;

    > p h3 {
      text-align: center;
      font-size: 32px;
      @media screen and (max-width: 1200px) {
        font-size: 18px;
      }
      @media screen and (max-width: 576px) {
        /* height: 55px; */
      }
      @media screen and (max-width: 435px) {
        font-size: 16px;
      }
    }
    @media screen and (max-width: 435px) {
      height: 120px;
    }
  }

  h2 {
    font-size: 65px;
    @media screen and (max-width: 1200px) {
      font-size: 27px;
    }

    @media screen and (max-width: 576px) {
      font-size: 20px;
    }

    @media screen and (max-width: 435px) {
      font-size: 16px;
    }
  }

  h2,
  h3 {
    margin: 0;
  }

  /* h3 {
    margin-top: 40px;
  } */

  small {
    text-align: left;
    color: black;
    margin-left: 4px;

    @media screen and (max-width: 576px) {
      font-size: 12px;
    }

    @media screen and (max-width: 435px) {
      font-size: 10px;
    }
  }

  p {
    font-size: 15px;
    margin: 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    /* border-bottom: 1px solid black; */

    @media screen and (max-width: 576px) {
      /* height: 10px; */
      font-size: 10px;
    }
    @media screen and (max-width: 435px) {
      /* height: 10px; */
      margin-bottom: 0;
    }
  }

  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    width: 100%;
    height: 56px;
    cursor: pointer;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 435px) {
      font-size: 12px;
    }
  }

  .demo-btn {
    background-color: #f58634;
    :hover {
      background-color: #86bc4c;
      color: black;
    }
  }

  > div:nth-child(3) {
    @media screen and (max-width: 500px) {
      font-size: 12px;
      text-align: left !important;
    }
  }
`;

export const PlanBox4 = styled.div`
  width: 25%;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

  /* border: 2px solid lightgray; */

  @media screen and (max-width: 600px) {
    width: 30%;
  }
  @media screen and (max-width: 420px) {
    padding-left: 5px;
    padding-right: 5px;
  }
  .xross-tick {
    height: 20px;
    margin-right: 5px;
    margin-bottom: -4px;
    @media screen and (max-width: 576px) {
      height: 10px;
      margin-bottom: 0;
    }
    @media screen and (max-width: 435px) {
      height: 10px;
      margin-bottom: 0;
    }
  }

  .custom-hr {
    height: 1px; /* Adjust the height of the line */
    width: 100%; /* Set the width to 100% to make it span the container */
    background-color: #cecece;
    /* Set the color of the line */
    opacity: 80%;
    margin: 10px 0; /* Add margin for spacing */
  }

  .strikethrough {
    display: block;
    color: darkgray;
    text-decoration: line-through;
  }

  .Heading-content {
    display: flex;
    /* text-align: center; */
    align-items: center;
  }

  .show-details-btn {
    color: black;
    background-color: #efefef;
    border: none;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 9px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;

    @media screen and (max-width: 435px) {
      padding: 10px 10px;
    }
  }

  .show-details-btn:hover {
    color: white;
    background-color: #03baed;

    border: none;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 9px;
    /* cursor: all-scroll; */
  }

  .plan-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 180px;

    > p h3 {
      text-align: center;
      font-size: 32px;
      @media screen and (max-width: 1200px) {
        font-size: 18px;
      }
      @media screen and (max-width: 576px) {
        /* height: 55px; */
      }
      @media screen and (max-width: 435px) {
        font-size: 16px;
      }
    }
    @media screen and (max-width: 435px) {
      height: 120px;
    }
  }

  h2 {
    font-size: 65px;
    @media screen and (max-width: 1200px) {
      font-size: 27px;
    }

    @media screen and (max-width: 576px) {
      font-size: 20px;
    }

    @media screen and (max-width: 435px) {
      font-size: 16px;
    }
  }

  h2,
  h3 {
    margin: 0;
  }

  small {
    text-align: left;
    color: black;
    margin-left: 4px;

    @media screen and (max-width: 576px) {
      font-size: 12px;
    }

    @media screen and (max-width: 435px) {
      font-size: 10px;
    }
  }

  p {
    font-size: 15px;
    margin: 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    /* border-bottom: 1px solid black; */

    @media screen and (max-width: 576px) {
      /* height: 10px; */
      font-size: 10px;
    }
    @media screen and (max-width: 435px) {
      /* height: 10px; */
      margin-bottom: 0;
    }
  }

  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    width: 100%;
    height: 56px;
    cursor: pointer;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 435px) {
      font-size: 12px;
    }
  }

  .demo-btn {
    background-color: #f58634;
    :hover {
      background-color: #86bc4c;
      color: black;
    }
  }

  > div:nth-child(3) {
    @media screen and (max-width: 500px) {
      font-size: 12px;
      text-align: left !important;
    }
  }
`;

export default PlansWrapper;
