import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SliderDiv from './sliderdiv.style';
import Star from '../../../../common/assets/image/agency/star.svg';
import User from '../../../../common/assets/image/agency/userImage.png';
import Dheeraj from '../../../../common/assets/image/agency/Dheeraj.webp';
import DevC from '../../../../common/assets/image/agency/DevC.webp';
import Kristen from '../../../../common/assets/image/agency/Kristen.png';
import Kabir from '../../../../common/assets/image/agency/Kabir.jpg';
import UpperQuotes from '../../../../common/assets/image/agency/upperQuotes.svg';
import LowerQuotes from '../../../../common/assets/image/agency/lowerQuotes.svg';

const SliderSection = () => {
  const [dotsMarginTop, setDotsMarginTop] = useState('-60px');

  useEffect(() => {
    const handleWindowResize = () => {
      const isMobileScreen = window.innerWidth <= 420;
      setDotsMarginTop(isMobileScreen ? '100px' : '-80px');
    };

    handleWindowResize(); // Set initial value
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    pauseOnHover: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    appendDots: (dots) => (
      <div style={{ position: 'absolute', bottom: '10px', width: '100%' }}>
        <ul
          style={{ margin: '0', textAlign: 'center', marginTop: dotsMarginTop }}
        >
          {dots}
        </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // ... (your SliderDiv components and other code)

  return (
    <div>
      <Slider {...settings}>
        <SliderDiv>
          <div className="slider-wrapper">
            <div className="main">
              <div className="img-background">
                <img
                  className="right-img"
                  src={Kabir}
                  alt={'image for slider'}
                />
                <h5>Kabir K.</h5>
                <p className="des">Founder Small-Business</p>
              </div>
              <div className="outer-background"></div>
            </div>
            <div className="text">
              <hr className="hr"></hr>
              <h2>
                "High quality data and good filters to narrow right audience"
              </h2>
              <div className="quotes-container">
                <img
                  className="upper-quotes"
                  src={UpperQuotes}
                  alt={'upper Quotes'}
                />
                <p>
                  I love a few things about the platform The filters available
                  let you zero in on the your target audience The high qulity of
                  data avaiable on the platform. If you are lookiing for
                  prospecting, especially in the B2B space, this should be an
                  essential tool in your toolkit Can have better email
                  functionality for sequencing and follow up emails from withing
                  the system. Review collected by and hosted on G2.com.{' '}
                  <span>
                    <a
                      target="_blank"
                      href="https://www.g2.com/products/clodura-ai/reviews/clodura-ai-review-8011457"
                    >
                      Read more
                    </a>
                  </span>
                  <br />
                  <br />
                </p>
                <img
                  className="lower-quotes"
                  src={LowerQuotes}
                  alt={'lower Quotes'}
                />
              </div>
              <div className="stars">
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
              </div>
            </div>
          </div>
        </SliderDiv>

        <SliderDiv>
          <div className="slider-wrapper">
            <div className="main">
              <div className="img-background">
                <img
                  className="right-img"
                  src={DevC}
                  alt={'image for slider'}
                />
                <h5>Dave C.</h5>
                <p className="des">Founder Small-Business</p>
              </div>
              <div className="outer-background"></div>
            </div>
            <div className="text">
              <hr className="hr"></hr>
              <h2>"A gamechanger to my lead-gen process"</h2>
              <div className="quotes-container">
                <img
                  className="upper-quotes"
                  src={UpperQuotes}
                  alt={'upper Quotes'}
                />
                <p>
                  What do you like best about Clodura.AI? After being
                  disappointed by the promises of other platforms, I took a
                  chance on Clodura.ai and I delighted I did! It has everything
                  I need to streamline my lead-gen process. The search filter is
                  a game changer for my lead generation. The database is great
                  and up to date, whilst their integrated email service means my
                  emails reach the inboxes of future clients.{' '}
                  <span>
                    <a
                      target="_blank"
                      href="https://www.g2.com/products/clodura-ai/reviews/clodura-ai-review-8001812"
                    >
                      Read more
                    </a>
                  </span>
                  <br />
                  <br />
                </p>
                <img
                  className="lower-quotes"
                  src={LowerQuotes}
                  alt={'lower Quotes'}
                />
              </div>
              <div className="stars">
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
              </div>
            </div>
          </div>
        </SliderDiv>

        <SliderDiv>
          <div className="slider-wrapper">
            <div className="main">
              <div className="img-background">
                <img
                  className="right-img"
                  src={Dheeraj}
                  alt={'image for slider'}
                />
                <h5>Dhiraj J.</h5>
                <p className="des">
                  AI Copywriter and Content Marketing Strategist, <br />{' '}
                  Small-Business
                </p>
              </div>
              <div className="outer-background"></div>
            </div>

            <div className="text">
              <hr className="hr"></hr>
              <h2>"Revolutionizing My Sales Efficiency and Lead Generation"</h2>
              <div className="quotes-container">
                <img
                  className="upper-quotes"
                  src={UpperQuotes}
                  alt={'upper Quotes'}
                />

                <p>
                  One of the things I love the most about Clodura.AI is how it
                  uses the power of artificial intelligence to generate highly
                  targeted leads. The data it provides is amazingly accurate -
                  95% accuracy on emails and direct dials - which helps me
                  connect with prospects quickly and seamlessly. The Org Chart
                  feature is an absolute game-changer, letting me pinpoint the
                  critical decision-makers within target organizations. This
                  makes my sales and marketing campaigns even more effective!{' '}
                  <span>
                    <a
                      href="https://www.g2.com/products/clodura-ai/reviews/clodura-ai-review-8193049"
                      target="_blank"
                    >
                      Read more
                    </a>
                  </span>
                  <br />
                </p>
                <img
                  className="lower-quotes"
                  src={LowerQuotes}
                  alt={'lower Quotes'}
                />
              </div>

              <div className="stars">
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
              </div>
            </div>
          </div>
        </SliderDiv>

        <SliderDiv>
          <div className="slider-wrapper">
            <div className="main">
              <div className="img-background">
                <img
                  className="right-img"
                  src={User}
                  alt={'image for slider'}
                />
                <h5>Rajesh I.</h5>
                <p className="des">
                  Director Strategic Business Development, <br />
                  Key Accounts Enterprise
                </p>
              </div>
              <div className="outer-background"></div>
            </div>
            <div className="text">
              <hr className="hr"></hr>
              <h2>"Great tool to generate quality leads"</h2>
              <div className="quotes-container">
                <img
                  className="upper-quotes"
                  src={UpperQuotes}
                  alt={'upper Quotes'}
                />
                <p>
                  I've recently had the pleasure of using Clodura.AI to generate
                  targeted leads for my business, and I'm incredibly impressed
                  with the results. Their platform offers an incredibly accurate
                  technographic data set, allowing us to generate a highly
                  targeted list of potential buyers with ease. Their
                  AI-recommended list of potential buyers also enabled us to
                  expand our sales outreach with confidence. Verified data was
                  also accurate and reliable every time, allowing us to quickly
                  generate leads of the highest quality. With the help of our
                  automated leadgen process provided{' '}
                  <span>
                    <a
                      href="https://www.g2.com/products/clodura-ai/reviews/"
                      target="_blank"
                    >
                      Read more
                    </a>
                  </span>
                </p>
                <img
                  className="lower-quotes"
                  src={LowerQuotes}
                  alt={'lower Quotes'}
                />
              </div>
              <div className="stars">
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
              </div>
            </div>
          </div>
        </SliderDiv>

        <SliderDiv>
          <div className="slider-wrapper">
            <div className="main">
              <div className="img-background">
                <img
                  className="right-img"
                  src={Kristen}
                  alt={'image for slider'}
                />
                <h5>Kristin K.</h5>
                <p className="des">Transition Specialist</p>
              </div>
              <div className="outer-background"></div>
            </div>
            <div className="text">
              <hr className="hr"></hr>
              <h2>"Great sales lead gen"</h2>
              <div className="quotes-container">
                <img
                  className="upper-quotes"
                  src={UpperQuotes}
                  alt={'upper Quotes'}
                />
                <p>
                  Provides solid leads with verified details. Very easy to use.
                  Support is top notch. Great additional features that Clodura
                  offers including emailing the business owner directly from
                  Clodura is a very good feature. It provides much warmer
                  interactions with clients by having prior knowledge if their
                  business handy ie revenue. It also saves myself a ton of time
                  scouring the internet vetting activity.{' '}
                  <span>
                    <a
                      href="https://www.g2.com/products/clodura-ai/reviews/clodura-ai-review-8344835"
                      target="_blank"
                    >
                      Read more
                    </a>
                  </span>
                </p>
                <img
                  className="lower-quotes"
                  src={LowerQuotes}
                  alt={'lower Quotes'}
                />
              </div>
              <div className="stars">
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
                <img className="star" src={Star} alt="rating-star" />
              </div>
            </div>
          </div>
        </SliderDiv>
      </Slider>
    </div>
  );
};

export default SliderSection;
