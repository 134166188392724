import styled from 'styled-components';

const PlansWrapper = styled.section`
  display: flex;
  max-width: 90%;
  margin: 20px auto 60px;
  /* margin: 20px 60px; */
  margin-top: 40px;
  padding: 0;
  align-items: center;

  .starter {
    border: 2px solid #03baed;
    /* box-shadow: 5px 6px #f58634; */
  }

  .info-container {
    position: relative;
  }

  .info-icon {
    margin-left: 2px; /* Add some space between the text and the info icon */
    cursor: pointer; /* Change cursor to pointer on hover */
    width: 12px;
  }

  .info-tooltip {
    position: absolute;
    top: 40px; /* Adjust the position of the tooltip */
    left: 0;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    display: none; /* Initially hide the tooltip */
    width: 250px;
    z-index: 9999;
    font-size: 12px;
    font-weight: 400;
    /* text-align: justify; */
    text-align: left;
  }

  /* Change display of tooltip to block when hovering over the info icon */
  .info-icon:hover + .info-tooltip {
    display: block;
  }

  .plan-desktop {
    position: relative;
  }

  /* .bg-dark {
    background: #f3f3f3;
  }

  .bg-dark:hover {
    background: #e8faff;
  }

  .bg-light {
    background: #f9f9f9;
  } */

  .box-with-upside-shadow {
    position: relative;
    height: 100%;
    border: 2px solid #03baed;
    box-sizing: border-box;

    @media screen and (max-width: 1024px) {
      height: auto;
    }

    @media screen and (max-width: 768px) {
      height: auto;
    }

    @media screen and (max-width: 480px) {
      height: auto;
    }

    @media screen and (max-width: 375px) {
      height: auto;
    }

    @media screen and (max-width: 320px) {
      height: auto;
    }
  }

  /* className="starter box-with-upside-shadow" */
  .box-with-upside-shadow::before {
    content: 'Most Popular';
    display: block;
    position: absolute;
    top: -20px;
    left: -2px;
    right: 2px;
    text-align: center;
    align-items: center;
    color: black;
    font-size: 16px;
    font-weight: bold;
    background-color: #03baed;
    width: calc(102% - 1.5px); /* Adjust the width to account for the padding */
    box-sizing: border-box;
    padding: 0 2px; /* Add left and right padding of 2 pixels */

    /* Responsive styles */
    @media screen and (max-width: 768px) {
      font-size: 14px;
      top: -15px;
      left: 0px;
      right: 0px;
      padding: 0 1px;
      /* margin-bottom: -20px; Adjust the margin to avoid interference */
    }

    @media screen and (max-width: 480px) {
      font-size: 12px;
      top: -10px;
      padding: 0;
      /* margin-bottom: -20px; Adjust the margin to avoid interference */
    }
  }

  .star-icon {
  }

  .fa-circle-xmark {
    font-size: 25px;
    color: red;
  }
  .fa-circle-check {
    font-size: 25px;
    color: green;
  }

  .plan-mobile {
    display: none;

    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 25px;
      column-gap: 20px;
      justify-content: flex-start;
    }

    @media screen and (max-width: 576px) {
      column-gap: 10px;
    }
  }

  .plan-desktop {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      width: 100%;
      overflow-y: auto;
    }
  }
`;

export const FeatBox = styled.div`
  width: 40%;
  /* padding: 20px; */
  /* background-color: #fffdfd; */
  /* border-radius: 2px; */
  /* border: 2px solid lightgray; */
  /* text-align: left; */

  @media screen and (max-width: 600px) {
    width: 30%;
  }
  @media screen and (max-width: 420px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  .strikethrough {
    display: block;
    color: darkgray;
    text-decoration: line-through;
  }

  .plan-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 150px;

    .main-title {
      margin-top: -12px;
      text-align: center;
    }

    > h3 {
      text-align: center;
      @media screen and (max-width: 1200px) {
        font-size: 18px;
      }
      @media screen and (max-width: 576px) {
        height: 55px;
      }
      @media screen and (max-width: 425px) {
        font-size: 16px;
      }
    }
  }

  .bg-dark {
    background: #f3f3f3;
  }

  .bg-dark:hover {
    background: #e8faff;
  }

  .bg-light {
    background: #f9f9f9;
  }

  .bg-light:hover {
    background: #e8faff;
  }

  .heading-section {
    border-bottom: 4px solid #03baed;
    border-top: 4px solid #03baed;
    width: 110%;
  }

  .big-heading {
    h2 {
      font-size: 32px;

      @media screen and (max-width: 1200px) {
        font-size: 27px;
      }

      @media screen and (max-width: 576px) {
        font-size: 14px;
      }

      @media screen and (max-width: 425px) {
        font-size: 10px !important;
      }
    }
  }

  .second-big-heading {
    h2 {
      font-size: 32px;

      @media screen and (max-width: 1200px) {
        font-size: 27px;
      }

      @media screen and (max-width: 576px) {
        font-size: 10px;
      }

      @media screen and (max-width: 425px) {
        font-size: 10px !important;
      }
    }
  }

  h2 {
    font-size: 32px;
    /* border-top: 4px solid #03baed;
    border-bottom: 4px solid #03baed; */

    @media screen and (max-width: 1200px) {
      font-size: 27px;
    }

    @media screen and (max-width: 576px) {
      font-size: 16px;
    }

    @media screen and (max-width: 425px) {
      font-size: 12px;
    }
  }

  h2,
  h3 {
    margin: 0;
  }

  small {
    text-align: center;
    color: gray;

    @media screen and (max-width: 576px) {
      font-size: 12px;
    }

    @media screen and (max-width: 425px) {
      font-size: 10px;
    }
  }

  p {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    height: 60px;
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 15px;
    @media screen and (max-width: 600px) {
      font-size: 12px;
      padding-left: 0;
    }
  }

  p:hover {
    background: #e8faff;
  }

  .small-p {
    /* position: relative; */
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    height: 50px;
    display: flex;
    /* text-align: right; */
    margin-left: 20px;
    @media screen and (max-width: 600px) {
      font-size: 10px;
      padding-left: 0;
      margin-left: 0px;
    }
  }

  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    width: 100%;
    height: 56px;
    cursor: pointer;
    margin-top: -10px;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 420px) {
      font-size: 12px;
    }
  }

  .under-btn-border {
    /* border-top: 1px solid rgba(0, 0, 0, 0.3); */
    /* margin-top: 8px; */
  }

  .demo-btn {
    background-color: #f58634;
    :hover {
      background-color: #86bc4c;
      color: black;
    }
  }

  > div:nth-child(3) {
    @media screen and (max-width: 500px) {
      font-size: 12px;
      text-align: left !important;
    }
  }
`;

export const PlanBox = styled.div`
  width: 20%;
  /* padding: 20px; */
  background-color: #fffdfd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 600px) {
    width: 30%;
  }
  @media screen and (max-width: 420px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  .strikethrough {
    display: block;
    color: darkgray;
    text-decoration: line-through;
  }

  .plan-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 150px;

    .main-title {
      margin-top: -12px;
      text-align: center;
    }

    > h3 {
      text-align: center;
      @media screen and (max-width: 1200px) {
        font-size: 18px;
      }
      @media screen and (max-width: 576px) {
        height: 55px;
      }
      @media screen and (max-width: 425px) {
        font-size: 16px;
      }
    }
  }

  .heading-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom: 4px solid #03baed;
    border-top: 4px solid #03baed;
    width: 110%;
    position: relative;
  }

  small {
    font-size: 10px;
    color: #666; /* Adjust color as needed */
    margin-top: 5px;
    text-align: left;
    @media screen and (max-width: 576px) {
      margin-top: 0;
    }

    @media screen and (max-width: 425px) {
      margin-top: 0;
    }
  }

  .heading-h2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .upper-text {
    position: absolute;
    top: -16px;
    padding: 2px 9px;
    border-radius: 4px;
    background: rgb(3, 186, 237);
    color: white;
    font-size: 12px;
    font-weight: 600;
    margin-left: -15px;

    @media screen and (max-width: 576px) {
      font-size: 8px;
      margin-left: 0px;
    }

    @media screen and (max-width: 425px) {
      font-size: 8px;
    }
  }

  .bg-dark {
    background: #f3f3f3;
    display: flex;
    justify-content: center;
  }

  .bg-dark:hover {
    background: #e8faff;
  }

  .bg-light {
    background: #f9f9f9;
    display: flex;
    justify-content: center;
  }

  .bg-light:hover {
    background: #e8faff;
  }

  h2 {
    font-size: 32px;
    text-align: center;

    @media screen and (max-width: 1200px) {
      font-size: 27px;
    }

    @media screen and (max-width: 576px) {
      font-size: 16px;
    }

    @media screen and (max-width: 425px) {
      font-size: 12px;
    }
  }

  h2,
  h3 {
    margin: 0;
  }

  small {
    text-align: center;
    color: gray;

    @media screen and (max-width: 576px) {
      font-size: 5px;
    }

    @media screen and (max-width: 425px) {
      font-size: 5px;
    }
  }

  p {
    font-size: 16px;
    /* font-weight: bold; */
    margin: 0;
    height: 60px;
    display: flex;
    align-items: center;
    text-align: center;
    /* padding-left: 20px; */
    @media screen and (max-width: 576px) {
      font-size: 10px;
    }

    @media screen and (max-width: 425px) {
      font-size: 10px;
    }
  }

  p:hover {
    background: #e8faff;
  }

  .power-up-label {
    display: inline-block;
    background-color: #03baed;
    color: white;
    font-weight: 600;
    padding: 4px 12px; /* Adjust padding as needed */
    border-radius: 35px; /* Adjust border-radius for rounded corners */
    font-size: 12px; /* Adjust font size using relative units */

    overflow: hidden; /* Hide any overflow content */
    white-space: nowrap; /* Prevent the text from wrapping */
    /* text-overflow: ellipsis; */
  }

  .small-p {
    font-size: 14px;
    margin: 0;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 576px) {
      font-size: 10px;
    }

    @media screen and (max-width: 425px) {
      font-size: 10px;
    }
  }

  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    width: 100%;
    height: 56px;
    cursor: pointer;
    margin-top: -10px;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 450px) {
      font-size: 10px;
    }
  }

  .under-btn-border {
    /* border-top: 2px solid #03baed; */
    /* margin-top: 8px; */
  }

  .demo-btn {
    background-color: #f58634;
    :hover {
      background-color: #86bc4c;
      color: black;
    }
  }

  > div:nth-child(3) {
    @media screen and (max-width: 500px) {
      font-size: 12px;
      text-align: left !important;
    }
  }
`;

export const PlanBox2 = styled.div`
  width: 25%;
  /* padding: 20px; */
  background-color: #fffdfd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 600px) {
    width: 30%;
  }
  @media screen and (max-width: 420px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  .strikethrough {
    display: block;
    color: darkgray;
    text-decoration: line-through;
  }

  .plan-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 150px;

    .main-title {
      margin-top: -12px;
      text-align: center;
    }

    > h3 {
      text-align: center;
      @media screen and (max-width: 1200px) {
        font-size: 18px;
      }
      @media screen and (max-width: 576px) {
        height: 55px;
      }
      @media screen and (max-width: 425px) {
        font-size: 16px;
      }
    }
  }

  .heading-section {
  }

  h2 {
    font-size: 32px;

    @media screen and (max-width: 1200px) {
      font-size: 27px;
    }

    @media screen and (max-width: 576px) {
      font-size: 20px;
    }

    @media screen and (max-width: 425px) {
      font-size: 16px;
    }
  }

  h2,
  h3 {
    margin: 0;
  }

  small {
    text-align: left;
    color: gray;

    @media screen and (max-width: 576px) {
      font-size: 12px;
    }

    @media screen and (max-width: 425px) {
      font-size: 10px;
    }
  }

  p {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    height: 65px;
    display: flex;
    align-items: center;
    text-align: right;
    padding-left: 15px;
  }

  .small-p {
    font-size: 14px;
    margin: 0;
    height: 50px;
    display: flex;
    align-items: center;
    text-align: left;
  }

  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    width: 100%;
    height: 56px;
    cursor: pointer;
    margin-top: -10px;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 420px) {
      font-size: 12px;
    }
  }

  .under-btn-border {
    /* border-top: 2px solid #03baed; */
    /* margin-top: 8px; */
  }

  .demo-btn {
    background-color: #f58634;
    :hover {
      background-color: #86bc4c;
      color: black;
    }
  }

  > div:nth-child(3) {
    @media screen and (max-width: 500px) {
      font-size: 12px;
      text-align: left !important;
    }
  }
`;

export const PlanBox3 = styled.div`
  width: 25%;
  /* padding: 20px; */
  background-color: #fffdfd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 600px) {
    width: 30%;
  }
  @media screen and (max-width: 420px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  .strikethrough {
    display: block;
    color: darkgray;
    text-decoration: line-through;
  }

  .plan-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 150px;

    .main-title {
      margin-top: -12px;
      text-align: center;
    }

    > h3 {
      text-align: center;
      @media screen and (max-width: 1200px) {
        font-size: 18px;
      }
      @media screen and (max-width: 576px) {
        height: 55px;
      }
      @media screen and (max-width: 425px) {
        font-size: 16px;
      }
    }
  }

  .heading-section {
  }

  h2 {
    font-size: 32px;

    @media screen and (max-width: 1200px) {
      font-size: 27px;
    }

    @media screen and (max-width: 576px) {
      font-size: 20px;
    }

    @media screen and (max-width: 425px) {
      font-size: 16px;
    }
  }

  h2,
  h3 {
    margin: 0;
  }

  small {
    text-align: center;
    color: gray;

    @media screen and (max-width: 576px) {
      font-size: 12px;
    }

    @media screen and (max-width: 425px) {
      font-size: 10px;
    }
  }

  p {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    height: 65px;
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 15px;
  }

  .small-p {
    font-size: 12px;
    margin: 0;
    height: 50px;
    display: flex;
    align-items: center;
    P {
      text-align: right;
    }
  }

  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    width: 100%;
    height: 56px;
    cursor: pointer;
    margin-top: -10px;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 420px) {
      font-size: 12px;
    }
  }

  .under-btn-border {
    /* border-top: 2px solid #03baed; */
    /* margin-top: 8px; */
  }

  .demo-btn {
    background-color: #f58634;
    :hover {
      background-color: #86bc4c;
      color: black;
    }
  }

  > div:nth-child(3) {
    @media screen and (max-width: 500px) {
      font-size: 12px;
      text-align: left !important;
    }
  }
`;

export const PlanBox4 = styled.div`
  width: 25%;
  /* padding: 20px; */
  background-color: #fffdfd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 600px) {
    width: 30%;
  }
  @media screen and (max-width: 420px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  .strikethrough {
    display: block;
    color: darkgray;
    text-decoration: line-through;
  }

  .plan-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 150px;

    .main-title {
      margin-top: -12px;
      text-align: center;
    }

    > h3 {
      text-align: center;
      @media screen and (max-width: 1200px) {
        font-size: 18px;
      }
      @media screen and (max-width: 576px) {
        height: 55px;
      }
      @media screen and (max-width: 425px) {
        font-size: 16px;
      }
    }
  }

  .heading-section {
  }

  h2 {
    font-size: 32px;

    @media screen and (max-width: 1200px) {
      font-size: 27px;
    }

    @media screen and (max-width: 576px) {
      font-size: 20px;
    }

    @media screen and (max-width: 425px) {
      font-size: 16px;
    }
  }

  h2,
  h3 {
    margin: 0;
  }

  small {
    text-align: center;
    color: gray;

    @media screen and (max-width: 576px) {
      font-size: 12px;
    }

    @media screen and (max-width: 425px) {
      font-size: 10px;
    }
  }

  p {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    height: 65px;
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 15px;
  }

  .small-p {
    font-size: 12px;
    margin: 0;
    height: 50px;
    display: flex;
    align-items: center;
    P {
      text-align: right;
    }
  }

  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    width: 100%;
    height: 56px;
    cursor: pointer;
    margin-top: -10px;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 420px) {
      font-size: 12px;
    }
  }

  .under-btn-border {
    /* border-top: 2px solid #03baed; */
    /* margin-top: 8px; */
  }

  .demo-btn {
    background-color: #f58634;
    :hover {
      background-color: #86bc4c;
      color: black;
    }
  }

  > div:nth-child(3) {
    @media screen and (max-width: 500px) {
      font-size: 12px;
      text-align: left !important;
    }
  }
`;

export default PlansWrapper;
