import styled from 'styled-components';

const BannerWrapper = styled.section`
  text-align: center;
  background-color: #f2fbfe;
  margin-top: 70px;
  padding: 60px;

  h1 {
    font-size: 42px;
  }

  .spn {
    color: #03baed;
  }
`;

export default BannerWrapper;
