import React, { useState } from 'react';
import PlansWrapper, { FeatBox, PlanBox } from './plans.style';
import PropTypes from 'prop-types';
import BlueCheck from '../../../../common/assets/image/pricing/blue-tick.svg';
import Info from '../../../../common/assets/image/pricing/info-italic.svg';
import xross from '../../../../common/assets/image/pricing/cross_circle.svg';
import check from '../../../../common/assets/image/pricing/tick_circle.svg';
import { Link } from 'gatsby';

const Plans = ({ button }) => {
  return (
    <>
      <h2 style={{ textAlign: 'center', fontSize: '40px' }}>
        <span style={{ color: '#03BAED' }}> Side-By-Side </span> Showdown
      </h2>
      <PlansWrapper>
        <div className='plan-desktop'>
          <>
            <FeatBox>
              <div className='feature-list'>
                <div className='heading-section'>
                  <h2>Prospecting</h2>
                </div>

                <p className='under-btn-border bg-dark info-container'>
                  Contacts Unlock
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Search and unlock the contacts verified corporate email
                    address. Credit will be counted for only Verified Corporate
                    Email Address unlocked and not for guessed email address. We
                    also recommend to not to add guessed email addresses to your
                    cadence and send emails to such email addresses. This may
                    affect the performance of your campaigns.
                  </span>
                </p>
                <p className='bg-light info-container'>
                  Company Org Charts
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Discover reporting structure for 18M+ companies.
                  </span>
                </p>
                <p className='bg-dark info-container'>
                  Company Report
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Number of company reports you can view and download
                  </span>
                </p>
                <p className='small-p info-container'>
                  View company reports
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Number of company reports you can view and download
                  </span>
                </p>
                <p className='small-p info-container'>
                  Download company reports
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Number of company reports you can view and download
                  </span>
                </p>
                <p className='bg-dark info-container'>
                  Select
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Bulk Select contacts at a time
                  </span>
                </p>
                <p className='bg-light info-container'>
                  Export Credits
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Export or download the unlocked contacts and direct dials in
                    CSV.
                  </span>
                </p>
                <p className='bg-dark info-container'>
                  Basic Search
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Basic Search include Contact Name, Seniority and Function,
                    Number of Employees, Location, Tags and Website Keywords.
                  </span>
                </p>
                {/* <p className="small-p ">Number of Searches</p> */}
                <p className='small-p info-container'>
                  Contact or Company Name
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Search and unlock contacts, companies by name.
                  </span>
                </p>
                <p className='small-p info-container'>
                  Seniority, Function, Job Title
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Search contacts by Seniority, Function, and Job Title level.
                  </span>
                </p>
                <p className='small-p'>Number of Employees</p>
                <p className='small-p info-container'>
                  Location
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Search contacts and/or company by Country, State, City.
                  </span>
                </p>
                <p className='small-p info-container'>
                  Industry
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Search company based on Industry which the company belongs
                    to.
                  </span>
                </p>
                <p className='small-p info-container'>
                  Company Size
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Search company based on company size.
                  </span>
                </p>
                <p className='bg-light '>Advanced Search Filters</p>
                <p className='small-p info-container'>
                  Tags and Website Keywords
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Search for contacts or companies associated with tags and
                    keywords found on their websites
                  </span>
                </p>
                <p className='small-p info-container'>
                  Company Revenue
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Search company based on their revenue.
                  </span>
                </p>
                <p className='small-p info-container'>
                  Type
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Search and Select whether it is a Product-based,
                    Service-based or a Solution-based company.
                  </span>
                </p>
                <p className='small-p info-container'>
                  Technology
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Search companies based on tools and technologies a company
                    uses.
                  </span>
                </p>
                <p className='small-p info-container'>
                  Funding
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Search companies based on the type of funding they have. s
                  </span>
                </p>
                <p className='bg-dark info-container'>
                  Buying Intent
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With Buying Intent and Hiring Areas, you can filter your
                    company search to those who have expressed interest in
                    specific products, services or technology and hirings in
                    specific company.
                  </span>
                </p>
                {/* <p className="bg-light info-container">
                  Job Changes
                  <img class="info-icon" src={Info} alt="Info" />
                  <span class="info-tooltip">
                    With Job Changes, you can update contacts when they change
                    jobs and allow you to see when your customers have changed
                    jobs to a new, relevant role within your territory.
                  </span>
                </p> */}
                <p className='bg-light info-container'>
                  Suppressions
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Suppression list is where you upload lists of websites,
                    emails, and contact names to automatically obtain additional
                    details.
                  </span>
                </p>
                <p className='small-p info-container'>
                  Contact Name Suppression
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Contact name suppression list allows you to upload lists of
                    contact names to automatically obtain additional details.
                  </span>
                </p>
                <p className='small-p info-container'>
                  Email ID Suppression
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Email address suppression list allows you to upload lists of
                    email ids to automatically obtain additional details.
                  </span>
                </p>
                <p className='small-p info-container'>
                  Website Suppression
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Website name suppression list allows you to upload lists of
                    website names to automatically obtain additional details.
                  </span>
                </p>
                <p className='bg-dark info-container'>
                  Search Saved
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Save your searches in case you want to come back to the same
                    search or want to save yourself the hassle of reapplying the
                    numerous filters repeatedly
                  </span>
                </p>
                <p className='bg-light info-container'>
                  Chrome Extension
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    The Chrome Extension can be used to find email addresses,
                    phone numbers, funding & revenue data, technology data, and
                    more directly from LinkedIn or any website on the web.
                    Additionally, if you have your mailbox linked, you can send
                    emails and make phone calls directly from LinkedIn or any
                    website on the web.
                  </span>
                </p>

                <div className='heading-section'>
                  <h2>Outreach</h2>
                </div>

                <p className='under-btn-border bg-dark'>Active Cadence</p>
                <p className='bg-light'>Daily Email Send Limit</p>
                <p className='bg-dark'>Basic Analytics</p>
                <p className='small-p'>Total Emails Sent</p>
                <p className='small-p'>Avg Unique Open Rate</p>
                <p className='small-p'>Avg Reply Rate</p>
                <p className='small-p'>Avg Unique Link Click Rate</p>
                <p className='small-p'>Avg Unsubscribe Rate</p>
                <p className='small-p'>Avg Hard Bounce Rate</p>
                <p className='small-p'>Email Open Time</p>
                <p className='small-p'>Email View By Device</p>
                <p className='small-p'>Contacts Emailed</p>
                <p className='small-p'>Companies Emailed</p>

                <p className='bg-dark'>Cadence Analytics</p>
                <p className='small-p info-container'>
                  Email Read/Open Analytics
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    This allows you to track performance of your campaigns and
                    emails to see which subject lines and email messaging leads
                    to the highest Open Rates.
                  </span>
                </p>
                <p className='small-p info-container'>
                  Email Link Click Analytics
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    This allows you to track performance of your campaigns and
                    emails to see which subject lines and email messaging leads
                    to the highest Link Click rates.
                  </span>
                </p>
                <p className='small-p info-container'>
                  Email Reply Analytics
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    This allows you to track performance of your campaigns and
                    emails to see which subject lines and email messaging leads
                    to the highest Email Reply rates.
                  </span>
                </p>
                <p className='small-p info-container'>
                  Email Bounce Rate Analytics
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    This allows you to track the Soft and Hard Bounce rates of
                    your campaigns and emails to see which contact list is most
                    updated or need to be updated.
                  </span>
                </p>
                <p className='bg-light info-container'>
                  Soft Leads Identification
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    This identifies the soft leads from your campaigns based on
                    various parameters like email read/open, link clicks, reply.
                  </span>
                </p>
                <p className='bg-dark info-container'>
                  Upload CSV
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Import or upload your contact lists CSV of Unlimited
                    contacts or suppression list of name, website or email list.
                  </span>
                </p>
                <p className='bg-light info-container'>
                  AI Writer Credits (Words)
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Use Clodura.AI's AI Writer to generate subject lines, email
                    messages, and personalized openers custom-tailored to your
                    contacts.
                  </span>
                </p>
                <p className='bg-dark info-container'>
                  Email Service Provider
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Integrate your Clodura.AI account with any email service
                    providers like Gmail, Outlook, MailChimp, SendGrid, and
                    other.
                  </span>
                </p>
                <p className='small-p'>Gmail</p>
                <p className='small-p'>Outlook</p>
                <p className='small-p'>Other</p>

                <div className='heading-section'>
                  <h2>Conversations</h2>
                </div>

                <p className='bg-light'>Direct Dial</p>
                <p className='small-p'>Search Partner Credits</p>
                <p className='small-p'>Free Credits</p>
                <p className='bg-dark'>Calling Disposition</p>

                <div className='heading-section'>
                  <h2>Integrations</h2>
                </div>

                <p className='bg-light'>CRM</p>

                <p className='small-p'>Salesforce</p>
                <p className='small-p'>HubSpot</p>
                <p className='small-p'>Freshsales</p>
                <p className='small-p'>Zoho</p>
                <p className='small-p'>MS Dynamics 365</p>
                <p className='small-p'>Pipedrive</p>
                <p className='small-p'>Insightly</p>

                {/* <p className="bg-dark info-container">
                  Bi-directional Integration
                  <img class="info-icon" src={Info} alt="Info" />
                  <span class="info-tooltip">
                    Our Advanced Bi-directional Integration allows you to Push &
                    Pull Prospects back and forth between Clodura.AI and your
                    CRM.
                  </span>
                </p>
                <p className="small-p">Pull From CRM</p>
                <p className="small-p">Push To CRM</p> */}

                <div className='heading-section big-heading'>
                  <h2>Data Enrichment</h2>
                </div>

                <p className='bg-dark info-container'>
                  CSV Enrichment
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Upload your contact or company data CSV and get it enriched
                    with 40+ data attributes.
                  </span>
                </p>
                {/* <p className="bg-dark info-container">
                  CRM Enrichment
                  <img class="info-icon" src={Info} alt="Info" />
                  <span class="info-tooltip">
                    Integrate contact or company data from your CRM and get it
                    enriched with 40+ data attributes.
                  </span>
                </p> */}
                {/* <p className="bg-light info-container">
                  API Access
                  <img class="info-icon" src={Info} alt="Info" />
                  <span class="info-tooltip">
                    Our API can be used to enrich the data and integrations with
                    your existing CRM with Clodura's data.
                  </span>
                </p> */}

                <div className='heading-section'>
                  <h2>Collaboration</h2>
                </div>

                <p className='bg-dark info-container'>
                  Team Features
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Add or remove the user from your account.
                  </span>
                </p>
                <p className='bg-light info-container'>
                  Team Dashboard
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    This will help you to track the performance of your each
                    team member based on how many contacts unlocked, direct
                    dials unlocked, email sent, their emails, campaign
                    performance and lot more.
                  </span>
                </p>

                <div className='heading-section second-big-heading'>
                  <h2>Account Setup & Support</h2>
                </div>

                <p className='bg-dark info-container'>
                  Email Setup
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Connect or setup your email from which you want to reach out
                    to your prospect list
                  </span>
                </p>
                <p className='bg-light info-container'>
                  Preferences
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With this, you can set the contact and company search i.e.
                    Buyers Persona, theme of the Clodura.AI account,
                    Notification, Passwords, App Locks.
                  </span>
                </p>
                <p className='bg-dark info-container'>
                  Billing
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With this, you can manage your billing details.
                  </span>
                </p>
                <p className='small-p info-container'>
                  GSTN
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With this, you can add the GSTN information of your company
                    and get bills accordingly.
                  </span>
                </p>
                <p className='small-p info-container'>
                  Invoice
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With this, you can generate the invoices to reimburse or
                    maintain billing.
                  </span>
                </p>
                <p className='bg-light'>Priority Email Support</p>
                <p className='bg-dark'>Priority Chat Support</p>

                {/* <div className="heading-section">
                  <h2>Power Up</h2>
                </div>

                <p className="bg-light info-container">
                  Direct Dials Credits
                  <img class="info-icon" src={Info} alt="Info" />
                  <span class="info-tooltip">
                    Direct dials credits from search partners.
                  </span>
                </p>
                <p className="bg-dark">Website Form Enrichment API Credits</p>
                <p className="bg-light">CRM Cleanup API Credits</p>
                <p className="bg-dark">CSV Enrichment</p>
                <p className="bg-light">CRM Enrichment</p>
                <p className="bg-light">AI Writer</p> */}
              </div>
            </FeatBox>
            <PlanBox>
              <div className='feature-list'>
                <div className='heading-section'>
                  <div className='upper-text'>Free Forever</div>
                  <div className='heading-h2'>
                    <h2>$0</h2>
                  </div>
                </div>

                <p className='under-btn-border bg-dark info-container'>
                  10000/Month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With this plan, user can unlock maximum 350 contacts per
                    day.
                  </span>
                </p>
                <p className='bg-light'>5/Month</p>
                <p className='bg-dark'></p>
                <p className='small-p info-container'>
                  5/Month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    View 5 company reports per month per account.
                  </span>
                </p>
                <p className='small-p'>-</p>
                <p className='bg-dark'>25</p>
                <p className='bg-light info-container'>
                  100/Month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Export or download the 100 unlocked contacts and direct
                    dials per month in CSV.
                  </span>
                </p>
                <p className='bg-dark'></p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                {/* <p className="small-p">
                  <img src={BlueCheck} />
                </p> */}
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-light'></p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='bg-dark'>1</p>
                {/* <p className="bg-light"></p> */}
                <p className='bg-light'></p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='bg-dark'>-</p>
                <p className='bg-light'>
                  <img src={BlueCheck} />
                </p>

                <div className='heading-section'>
                  <div className='upper-text'>Free Forever</div>
                  <div className='heading-h2'>
                    <h2>$0</h2>
                  </div>
                </div>

                <p className='under-btn-border bg-dark info-container'>
                  2
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With Cadence, you will only be able to create up to 2
                    Cadences.
                  </span>
                </p>
                <p className='bg-light'>200/Day</p>
                <p className='bg-dark'></p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>

                <p className='bg-dark'></p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='bg-light'>-</p>

                <p className='bg-dark'>-</p>
                <p className='bg-light info-container'>
                  1200 Words/Month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Use Clodura.AI's AI Writer to generate subject lines, email
                    messages, and personalized openers custom-tailored to your
                    contacts. This plan is limited to 1200 words per account per
                    month.
                  </span>
                </p>
                <p className='bg-dark'></p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>-</p>

                <div className='heading-section'>
                  <div className='upper-text'>Free Forever</div>
                  <div className='heading-h2'>
                    <h2>$0</h2>
                  </div>
                </div>

                <p className='bg-light'></p>
                <p className='small-p info-container'>
                  05/Month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock direct dials with 05 Search Partner credits per
                    month.
                  </span>
                </p>
                <p className='small-p info-container'>
                  12M
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock 100 direct dials per month from 12 million direct
                    dials present on the platform.
                  </span>
                </p>
                <p className='bg-dark'>-</p>

                <div className='heading-section'>
                  <div className='upper-text'>Free Forever</div>
                  <div className='heading-h2'>
                    <h2>$0</h2>
                  </div>
                </div>

                <p className='bg-light'></p>

                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>

                {/* <p className="bg-dark"></p>
                <p className="small-p"></p>
                <p className="small-p"></p> */}

                <div className='heading-section'>
                  <div className='upper-text'>Free Forever</div>
                  <div className='heading-h2'>
                    <h2>$0</h2>
                  </div>
                </div>

                <p className='bg-dark'>-</p>
                {/* <p className="bg-dark"></p> */}
                {/* <p className="bg-light">
                  <img src={BlueCheck} />
                </p> */}

                <div className='heading-section'>
                  <div className='upper-text'>Free Forever</div>
                  <div className='heading-h2'>
                    <h2>$0</h2>
                  </div>
                </div>

                <p className='bg-dark'>-</p>
                <p className='bg-light'>-</p>

                <div className='heading-section'>
                  <div className='upper-text'>Free Forever</div>
                  <div className='heading-h2'>
                    <h2>$0</h2>
                  </div>
                </div>

                <p className='bg-dark'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-light'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-dark'></p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='bg-light'>-</p>
                <p className='bg-dark'>-</p>

                {/* <div className="heading-section">
                  <div className="upper-text">Free Forever</div>
                  <div className="heading-h2">
                    <h2>$0</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>
                <p className="bg-light">
                  <div className="power-up-label">+ Power Up</div>
                </p>
                <p className="bg-dark">
                  <div className="power-up-label">+ Power Up</div>
                </p>
                <p className="bg-light">
                  <div className="power-up-label">+ Power Up</div>
                </p>
                <p className="bg-dark">
                  <div className="power-up-label">+ Power Up</div>
                </p>
                <p className="bg-light">
                  <div className="power-up-label">+ Power Up</div>
                </p> */}
                <Link href='/app/#/auth/register'>
                  <button style={{ marginTop: '10px' }} className='btn'>
                    Free Sign Up
                  </button>
                </Link>
              </div>
            </PlanBox>
            <PlanBox>
              <div className='feature-list'>
                <div className='heading-section'>
                  <div className='upper-text'>Solo</div>
                  <div className='heading-h2'>
                    <h2>$8</h2>
                    <small> per month</small>
                  </div>
                </div>

                <p className='under-btn-border bg-dark info-container'>
                  1000/Month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With this, user can unlock max 100 contacts per day.
                  </span>
                </p>
                <p className='bg-light'>10/Month</p>
                <p className='bg-dark'></p>
                <p className='small-p info-container'>
                  10/Month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    View 10 company reports per month per account.
                  </span>
                </p>
                <p className='small-p'>-</p>
                <p className='bg-dark'>100</p>
                <p className='bg-light info-container'>
                  1000/Month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Export or download the 1000 unlocked contacts and direct
                    dials per month in CSV.
                  </span>
                </p>
                <p className='bg-dark'></p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                {/* <p className="small-p">
                  <img src={BlueCheck} />
                </p> */}
                <p className='bg-light'></p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='bg-dark'>2</p>
                {/* <p className="bg-light"></p> */}
                <p className='bg-light'></p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='bg-dark'>-</p>
                <p className='bg-light'>
                  <img src={BlueCheck} />
                </p>

                <div className='heading-section'>
                  <div className='upper-text'>Solo</div>
                  <div className='heading-h2'>
                    <h2>$8</h2>
                    <small> per month</small>
                  </div>
                </div>

                <p className='under-btn-border bg-dark info-container'>
                  10
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With Cadence, you will only be able to create up to 10
                    Cadences.
                  </span>
                </p>
                <p className='bg-light'>500/Day</p>
                <p className='bg-dark'></p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>

                <p className='bg-dark'></p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='bg-light'>-</p>
                <p className='bg-dark'>-</p>
                <p className='bg-light info-container'>
                  2000 Words/Month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Use Clodura.AI's AI Writer to generate subject lines, email
                    messages, and personalized openers custom-tailored to your
                    contacts. This plan is limited to 2K words per account per
                    month.
                  </span>
                </p>
                <p className='bg-dark'></p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>-</p>

                <div className='heading-section'>
                  <div className='upper-text'>Solo</div>
                  <div className='heading-h2'>
                    <h2>$8</h2>
                    <small> per month</small>
                  </div>
                </div>

                <p className='bg-light'></p>
                <p className='small-p info-container'>
                  10/Month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock direct dials with 10 Search Partner credits per month
                  </span>
                </p>
                <p className='small-p info-container'>
                  12M
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Use Clodura.AI's AI Writer to generate subject lines, email
                    messages, and personalized openers custom-tailored to your
                    contacts. This plan is limited to 2K words per account per
                    month.
                  </span>
                </p>
                <p className='bg-dark'>100 Disposition/Month</p>

                <div className='heading-section'>
                  <div className='upper-text'>Solo</div>
                  <div className='heading-h2'>
                    <h2>$8</h2>
                    <small> per month</small>
                  </div>
                </div>

                <p className='bg-light'></p>

                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                {/* 
                <p className="bg-dark"></p>
                <p className="small-p"></p>
                <p className="small-p"></p> */}

                <div className='heading-section'>
                  <div className='upper-text'>Solo</div>
                  <div className='heading-h2'>
                    <h2>$8</h2>
                    <small> per month</small>
                  </div>
                </div>

                <p className='bg-dark'>-</p>
                {/* <p className="bg-dark"></p> */}
                {/* <p className="bg-light">
                  <img src={BlueCheck} />
                </p> */}

                <div className='heading-section'>
                  <div className='upper-text'>Solo</div>
                  <div className='heading-h2'>
                    <h2>$8</h2>
                    <small>per month</small>
                  </div>
                </div>

                <p className='bg-dark'>-</p>
                <p className='bg-light'>-</p>

                <div className='heading-section'>
                  <div className='upper-text'>Solo</div>
                  <div className='heading-h2'>
                    <h2>$8</h2>
                    <small> per month</small>
                  </div>
                </div>

                <p className='bg-dark'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-light'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-dark'></p>
                <p className='small-p'>-</p>
                <p className='small-p info-container'>
                  Personal Only
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With this, you can get the invoice on personal name and not
                    the company name.
                  </span>
                </p>
                <p className='bg-light'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-dark'>-</p>

                {/* <div className="heading-section">
                  <div className="upper-text">Solo</div>
                  <div className="heading-h2">
                    <h2>$9</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>

                <p className="bg-light">
                  <div className="power-up-label">+ Power Up</div>
                </p>
                <p className="bg-dark">
                  <div className="power-up-label">+ Power Up</div>
                </p>
                <p className="bg-light">
                  <div className="power-up-label">+ Power Up</div>
                </p>
                <p className="bg-dark">
                  <div className="power-up-label">+ Power Up</div>
                </p>
                <p className="bg-light">
                  <div className="power-up-label">+ Power Up</div>
                </p> */}
              </div>
              <Link href='/app/#/auth/register'>
                <button style={{ marginTop: '10px' }} className='btn'>
                  Free Sign Up
                </button>
              </Link>
            </PlanBox>
            <PlanBox>
              <div className='feature-list'>
                <div className='heading-section'>
                  <div className='upper-text'>Prospect</div>
                  <div className='heading-h2'>
                    <h2>$33</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>

                <p className='under-btn-border bg-dark info-container'>
                  Unlimited
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With this plan, Users can unlock 3000 contacts per account
                    per day.
                  </span>
                </p>
                <p className='bg-light'>100/Month</p>
                <p className='bg-dark'></p>
                <p className='small-p info-container'>
                  100/Month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    View 100 company reports per month per account.
                  </span>
                </p>
                <p className='small-p'>-</p>
                <p className='bg-dark'>10000</p>
                <p className='bg-light info-container'>
                  1000/Month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Export or download the 1000 unlocked contacts and direct
                    dials per month per user in CSV.
                  </span>
                </p>
                <p className='bg-dark'></p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                {/* <p className="small-p">
                  <img src={BlueCheck} />
                </p> */}
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-light'></p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-dark info-container'>
                  5
                  {/* <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Users on this plan can utilize buying intents unlimited by
                    adding them one at a time in filters.
                  </span> */}
                </p>
                {/* <p className="bg-light">10</p> */}
                <p className='bg-light'></p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='bg-dark'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-light'>
                  <img src={BlueCheck} />
                </p>

                <div className='heading-section'>
                  <div className='upper-text'>Prospect</div>
                  <div className='heading-h2'>
                    <h2>$33</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>

                <p className='under-btn-border bg-dark info-container'>
                  30
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With Cadence, you will only be able to create up to 30
                    Cadences.
                  </span>
                </p>
                <p className='bg-light'>1500/Day</p>
                <p className='bg-dark'></p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>

                <p className='bg-dark'></p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='small-p'>-</p>
                <p className='bg-light'>-</p>
                <p className='bg-dark info-container'>
                  2 /Month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Import or upload your contact lists 02 CSVs of unlimited
                    contacts.
                  </span>
                </p>
                <p className='bg-light info-container'>
                  10000 Words/Month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Use Clodura.AI's AI Writer to generate subject lines, email
                    messages, and personalized openers custom-tailored to your
                    contacts. This plan is limited to 10K words per account per
                    month.
                  </span>
                </p>
                <p className='bg-dark'></p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>-</p>
                {/* <p className="small-p">-</p> */}

                <div className='heading-section'>
                  <div className='upper-text'>Prospect</div>
                  <div className='heading-h2'>
                    <h2>$33</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>

                <p className='bg-light'></p>
                <p className='small-p info-container'>
                  25/Month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock direct dials with 25 Search Partner credits per month
                    per user.
                  </span>
                </p>
                <p className='small-p info-container'>
                  12M
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock unlimited direct dials per month from 12 million
                    direct dials present on the platform.
                  </span>
                </p>
                <p className='bg-dark'>1000 Disposition/Month</p>

                <div className='heading-section'>
                  <div className='upper-text'>Prospect</div>
                  <div className='heading-h2'>
                    <h2>$33</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>

                <p className='bg-light'></p>

                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>

                {/* <p className="bg-dark"></p>
                <p className="small-p">
                  <img src={BlueCheck} />
                </p>
                <p className="small-p">
                  <img src={BlueCheck} />
                </p> */}

                <div className='heading-section'>
                  <div className='upper-text'>Prospect</div>
                  <div className='heading-h2'>
                    <h2>$33</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>

                <p className='bg-dark'>-</p>
                {/* <p className="bg-dark"></p> */}
                {/* <p className="bg-light">
                  <img src={BlueCheck} />
                </p> */}

                <div className='heading-section'>
                  <div className='upper-text'>Prospect</div>
                  <div className='heading-h2'>
                    <h2>$33</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>

                <p className='bg-dark'>-</p>
                <p className='bg-light'>-</p>

                <div className='heading-section'>
                  <div className='upper-text'>Prospect</div>
                  <div className='heading-h2'>
                    <h2>$33</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>

                <p className='bg-dark'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-light'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-dark'></p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-light'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-dark'>
                  <img src={BlueCheck} />
                </p>

                {/* <div className="heading-section">
                  <div className="upper-text">Prospect</div>
                  <div className="heading-h2">
                    <h2>$33</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div> */}

                {/* <p className="bg-light">
                  <div className="power-up-label">+ Power Up</div>
                </p>
                <p className="bg-dark">
                  <div className="power-up-label">+ Power Up</div>
                </p>
                <p className="bg-light">
                  <div className="power-up-label">+ Power Up</div>
                </p>
                <p className="bg-dark">
                  <div className="power-up-label">+ Power Up</div>
                </p>

                <p className="bg-light">
                  <div className="power-up-label">+ Power Up</div>
                </p> */}
              </div>
              <Link href='/app/#/auth/register'>
                <button style={{ marginTop: '10px' }} className='btn'>
                  Free Sign Up
                </button>
              </Link>
            </PlanBox>
            <PlanBox>
              <div className='feature-list'>
                <div style={{ width: '100%' }} className='heading-section'>
                  <div className='upper-text'>Prospect Pro</div>
                  <div className='heading-h2'>
                    <h2>$58</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>

                <p className='under-btn-border bg-dark info-container'>
                  Unlimited
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With this plan, Users can unlock 10000 contacts per user per
                    day.
                  </span>
                </p>
                <p className='bg-light'>Unlimited</p>
                <p className='bg-dark'></p>
                <p className='small-p info-container'>
                  Unlimited
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    View unlimited company reports per month per user.
                  </span>
                </p>
                <p className='small-p info-container'>
                  Unlimited
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Download unlimited company reports per month per user.
                  </span>
                </p>
                <p className='bg-dark'>10000</p>
                <p className='bg-light info-container'>
                  2000/Month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Export or download the 2000 unlocked contacts and direct
                    dials per month per user in CSV.
                  </span>
                </p>
                <p className='bg-dark'></p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                {/* <p className="small-p">
                  <img src={BlueCheck} />
                </p> */}
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-light'></p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-dark info-container'>
                  10
                  {/* <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Users on this plan can utilize buying intents unlimited by
                    adding them one at a time in filters.
                  </span> */}
                </p>
                {/* <p className="bg-light">Unlimited</p> */}
                <p className='bg-light'></p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-dark'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-light'>
                  <img src={BlueCheck} />
                </p>

                <div style={{ width: '100%' }} className='heading-section'>
                  <div className='upper-text'>Prospect Pro</div>
                  <div className='heading-h2'>
                    <h2>$58</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>

                <p className='under-btn-border bg-dark info-container'>
                  Unlimited
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With this, you can create as many cadences as you'd like to
                    continue testing your messaging to improve success rates.
                  </span>
                </p>
                <p className='bg-light'>Unlimited</p>
                <p className='bg-dark'></p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>

                <p className='bg-dark'></p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-light'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-dark info-container'>
                  Unlimited
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Import or upload your contact lists CSV of unlimited
                    contacts or suppression list of name, website or email list.
                  </span>
                </p>
                <p className='bg-light info-container'>
                  Unlimited
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Use Clodura.AI's AI Writer to generate subject lines, email
                    messages, and personalized openers custom-tailored to your
                    contacts. This plan is limited to 3,00,000 words per user
                    per month.
                  </span>
                </p>
                <p className='bg-dark'></p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>

                <div style={{ width: '100%' }} className='heading-section'>
                  <div className='upper-text'>Prospect Pro</div>
                  <div className='heading-h2'>
                    <h2>$58</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>

                <p className='bg-light'></p>
                <p className='small-p info-container'>
                  50/Month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock direct dials with 50 Search Partner credits per month
                    per user.
                  </span>
                </p>
                <p className='small-p info-container'>
                  12M
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock unlimited direct dials per month from 12 million
                    direct dials present on the platform.
                  </span>
                </p>
                <p className='bg-dark'>10000 Disposition/Month</p>

                <div style={{ width: '100%' }} className='heading-section'>
                  <div className='upper-text'>Prospect Pro</div>
                  <div className='heading-h2'>
                    <h2>$58</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>

                <p className='bg-light'></p>

                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                {/* 
                <p className="bg-dark"></p>
                <p className="small-p">
                  <img src={BlueCheck} />
                </p>
                <p className="small-p">
                  <img src={BlueCheck} />
                </p> */}

                <div style={{ width: '100%' }} className='heading-section'>
                  <div className='upper-text'>Prospect Pro</div>
                  <div className='heading-h2'>
                    <h2>$58</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>

                <p className='bg-dark'>
                  <img src={BlueCheck} />
                </p>
                {/* <p className="bg-dark">
                  <img src={BlueCheck} />
                </p> */}
                {/* <p className="bg-light">
                  <img src={BlueCheck} />
                </p> */}

                <div style={{ width: '100%' }} className='heading-section'>
                  <div className='upper-text'>Prospect Pro</div>
                  <div className='heading-h2'>
                    <h2>$58</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>

                <p className='bg-dark'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-light'>
                  <img src={BlueCheck} />
                </p>

                <div style={{ width: '100%' }} className='heading-section'>
                  <div className='upper-text'>Prospect Pro</div>
                  <div className='heading-h2'>
                    <h2>$58</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>

                <p className='bg-dark'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-light'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-dark'></p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='small-p'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-light'>
                  <img src={BlueCheck} />
                </p>
                <p className='bg-dark'>
                  <img src={BlueCheck} />
                </p>

                {/* <div style={{ width: '100%' }} className="heading-section">
                  <div className="upper-text">Prospect Pro</div>
                  <div className="heading-h2">
                    <h2>$58</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>

                <p className="bg-light">
                  <div className="power-up-label">+ Power Up</div>
                </p>
                <p className="bg-dark">
                  <div className="power-up-label">+ Power Up</div>
                </p>
                <p className="bg-light">
                  <div className="power-up-label">+ Power Up</div>
                </p>
                <p className="bg-dark">
                  <div className="power-up-label">+ Power Up</div>
                </p>

                <p className="bg-light">-</p> */}
              </div>
              <Link href='/app/#/auth/register'>
                <button style={{ marginTop: '10px' }} className='btn'>
                  Free Sign Up
                </button>
              </Link>
            </PlanBox>
          </>
        </div>
      </PlansWrapper>
    </>
  );
};

Plans.propTypes = {
  button: PropTypes.object,
};

Plans.defaultProps = {
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'tertiaryWithBg',
    minHeight: 'auto',
    height: '40px',
  },
};

export default Plans;
