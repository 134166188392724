import React, { useState, useRef } from 'react';
import PlansWrapper, {
  PlanSwitchBox,
  PlanBox,
  PlanBox2,
  PlanBox3,
  PlanBox4,
} from './plans.style';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import G2Slider from 'containers/SaasModern/G2Badges';

import BlueCheck from '../../../../common/assets/image/pricing/black-tick.svg';
import Info from '../../../../common/assets/image/pricing/info-italic.svg';
import StarIcon from '../../../../common/assets/image/pricing/StarIcon1.svg';
import BannerForPrice from '../../../../common/assets/image/pricing/banner.svg';
import BannerForPriceLeft from '../../../../common/assets/image/pricing/Arrow-Left.svg';
import MonthPlanePage from '../MainPlanPage';
import YearPlanePage from '../YearPricePage';
import { Link } from 'gatsby';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import './plans.css';

const Plans = ({ button, description }) => {
  const [timeperiod, setTimePeriod] = useState(1); // 0 -> monthly, 1 -> yearly
  const [currency, setCurrency] = useState(0); // 0 -> usd, 1 -> inr
  const monthlyUSD = timeperiod === 0 && currency === 0;
  const yearlyUSD = timeperiod === 1 && currency === 0;

  const detailsRef = useRef(null);

  const scrollToDetails = () => {
    if (detailsRef.current) {
      const yOffset = -120; // Adjust this value to set the spacing
      const y =
        detailsRef.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className='switchbox-container'>
        <div className='switchbox-info'>
          <Heading
            className='switchbox-heading'
            as='h1'
            content={
              <span>
                Plans
                <span style={{ color: '#03baed' }}> & </span>
                Prices
              </span>
            }
          />
          <p className='heading-section-p'>
            Everything you need to generate leads and convert them into clients.
          </p>

          <div className='btn-section'>
            <div className='banner'>
              <img className='banner-img' src={BannerForPriceLeft} />
            </div>
            <div className='switchbox switchbox-timeperiod'>
              <button
                className={timeperiod === 1 && 'button-highlight'}
                onClick={() => setTimePeriod(1)}
              >
                Yearly Billing
              </button>
              <button
                className={timeperiod === 0 && 'button-highlight'}
                onClick={() => setTimePeriod(0)}
              >
                Monthly Billing
              </button>
            </div>
          </div>
        </div>
      </div>

      <PlansWrapper>
        <div className='plan-desktop'>
          {monthlyUSD && (
            <>
              <PlanBox>
                <div className='plan-info'>
                  <p style={{ marginTop: '20px' }}>
                    <h3>FREE FOREVER</h3>
                    <span className='sub-font'>&#160;</span>
                  </p>
                  <div
                    className='Heading-content'
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <h2>$ 0</h2>
                    <span className='sub-font-head'>
                      No Credit Card Required
                    </span>
                  </div>
                  <br />
                </div>
                <div className='custom-hr'></div>
                <p className='header-p'>
                  Unlimited Email Credits
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Clodura.AI’s Free Forever plan operate under a Fair Usage
                    Policy in order to prevent potential abuse. For non-paying
                    customers using an email address with a valid and verified
                    (by Clodura.AI in its sole discretion) corporate domain,
                    Clodura.AI has implemented a credit limit of 10,000 credits
                    per account per month to prevent abuse. Therefore, we
                    recommend that you use an email address with a valid
                    corporate domain in connection with your Clodura.AI account.
                    Non-paying customers using an email address with generic
                    email address have a lower credit limit of 100 credits per
                    account per month.
                  </span>
                </p>
                <p className='header-p'>100 Export Credits / month</p>
                <p className='header-p'>
                  12M Clodura Direct Dials Credits / month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock direct dials from Clodura.AI's 12 million direct
                    dials database for FREE.
                  </span>
                </p>
                <p className='header-p'>
                  5 Partners Direct Dials Credits / month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock 5 direct dials per month from Clodura.AI's Search
                    Partners over 108M direct dials database for FREE. These
                    credits are available to non-paying users with valid
                    corporate email address only.
                  </span>
                </p>
                {/* <p className='header-p'></p> */}
                <div className='custom-hr'></div>
                <Link href='/app/#/auth/register'>
                  <button className='btn'>Free Sign Up</button>
                </Link>
                <div className='planPlus'></div>
                <div className='feature-list'>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Active Cadence
                    <span className='sub-font'> (2 Cadence Limit)</span>
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip-for-header'>
                      Cadences are used to create an automated or
                      semi-automated, repeatable process to get in contact with
                      prospects after you have their contact information. With
                      Cadence, you will only be able to create up to 2 Cadences.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Send 200 emails/day
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      This is a limit of how many emails you can send through
                      Clodura.AI each day.
                    </span>
                  </p>

                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    AI Writer Credits
                    <span className='sub-font'> (1200 Words per month)</span>
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip-for-section'>
                      Use Clodura.AI's AI Writer to generate subject lines,
                      email messages, and personalized openers custom-tailored
                      to your contacts.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    05 Company Org Charts
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Discover 05 companies org charts/reporting structure per
                      month from 18M+ companies.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    View 05 Company Reports
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Number of company reports you can view per month
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Basic Search <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      For more detail about basic search filters, please see the
                      Side-by-Side Showdown.
                    </span>
                  </p>

                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Chrome Extension
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      The Chrome Extension can be used to find email addresses,
                      phone numbers, funding & revenue data, technology data,
                      and more directly from LinkedIn or any website on the web.
                      Additionally, if you have your mailbox linked, you can
                      send emails and make phone calls directly from LinkedIn or
                      any website on the web.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Basic Analytics
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      This allows you to track performance of your campaigns and
                      emails with basic details. For more detail about basic
                      analytics, please see the Side-by-Side Showdown.
                    </span>
                  </p>
                  {/* <p class="info-container">
                    <img class="xross-tick" src={BlueCheck} alt="Xross Image" />
                    API Access
                    <img class="info-icon" src={Info} alt="Info" />
                    <span class="info-tooltip">
                      Our API can be used to enrich the data and integrations
                      with your existing CRM with Clodura's data.
                    </span>
                  </p> */}
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Gmail & Outook Integration
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Integrate your Clodura.AI account with email service
                      providers like Gmail, Outlook.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Email Setup
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Connect or setup your email from which you want to reach
                      out to your prospect list
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Preferences
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      With this, you can set the contact and company search i.e.
                      Buyers Persona, theme of the Clodura.AI account,
                      Notification, Passwords, App Locks.
                    </span>
                  </p>
                  {/* <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      Direct Dials Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span class="info-tooltip">
                        Direct dials credits from search partners.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div>

                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      Website Form Enrichment API Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span class="info-tooltip">
                        Power up the limit of website form enrichment and boost
                        up the lead gen forms on the websites.
                      </span>
                    </div>
                    <div style={{ marginTop: '2px' }} class="power-up-label">
                      + Power Up
                    </div>
                  </div>

                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      CRM Cleanup API Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span class="info-tooltip">
                        Power up and cleanup the outdated, messy CRM data with
                        updated, single entry per contact or company, social
                        unique identifier in your CRM with CRM Cleanup API
                        credits.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div>
                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      CSV Enrichment
                      <img class="info-icon" src={Info} alt="Info" />
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span class="info-tooltip">
                        Power up and enrich your contact or company CSV with 40+
                        data attributes.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div> */}
                  <p></p>
                </div>

                <button className='show-details-btn' onClick={scrollToDetails}>
                  Show Comparison
                </button>
              </PlanBox>

              <PlanBox2 className='box-with-upside-shadow'>
                <div className='plan-info'>
                  <p style={{ marginTop: '20px' }}>
                    <h3>SOLO</h3>
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip-for-header'>
                      To avail this plan, you need to have your account signed
                      up with one of the generic email address like Gmail,
                      yahoo, and other only. User with Corporate or domain
                      emails address won't be able to avail this plan.
                      <br />
                      Purpose of this plan is to solely support and encourage
                      the Freelancer, Solo Entrepreneurs community.
                    </span>
                  </p>
                  <div className='Heading-content'>
                    <h2>$ 9</h2>
                    <small>per month</small>
                  </div>
                  <br />
                </div>
                <div className='custom-hr'></div>
                <p className='header-p'>
                  1000 Email credits/month per account
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With this, user can unlock max 100 contacts per day.
                  </span>
                </p>
                <p className='header-p'>1000 Export Credits / month</p>
                <p className='header-p'>
                  12M Clodura Direct Dials Credits / month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock direct dials from Clodura.AI's 12 million direct
                    dials database.
                  </span>
                </p>
                <p className='header-p'>
                  10 Partners Direct Dials Credits / month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock 10 direct dials per month from Clodura.AI's Search
                    Partners over 108M direct dials database.
                  </span>
                </p>
                <div className='custom-hr'></div>
                <Link href='/app/#/auth/register'>
                  <button className='btn'>Free Sign Up</button>
                </Link>
                <div className='planPlus'>Everything in Free Forever Plus:</div>
                <div className='feature-list'>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Active Cadence
                    <span className='sub-font'> (10 Cadence Limit)</span>
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip-for-header'>
                      Cadences are used to create an automated or
                      semi-automated, repeatable process to get in contact with
                      prospects after you have their contact information. With
                      Cadence, you will only be able to create up to 10
                      Cadences.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Send 500 emails/day
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      This is a limit of how many emails you can send through
                      Clodura.AI each day.
                    </span>
                  </p>

                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    AI Writer Credits
                    <span className='sub-font'> (2000 Words per month)</span>
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip-for-section'>
                      Use Clodura.AI's AI Writer to generate subject lines,
                      email messages, and personalized openers custom-tailored
                      to your contacts.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Select 100 Records at a time
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      You can select 100 records at a time, instead of standard
                      01.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Calling Disposition
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Call disposition is a label representing the outcome of an
                      inbound or outbound call.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Billing <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      With this, you can manage your billing details and can
                      generate the invoices to reimburse or maintain billing.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Priority Email Support Only
                  </p>
                  {/* 
                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      Direct Dials Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span class="info-tooltip">
                        Direct dials credits from search partners.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div>

                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      Website Form Enrichment API Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span class="info-tooltip">
                        Power up the limit of website form enrichment and boost
                        up the lead gen forms on the websites.
                      </span>
                    </div>
                    <div style={{ marginTop: '2px' }} class="power-up-label">
                      + Power Up
                    </div>
                  </div>

                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      CRM Cleanup API Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span class="info-tooltip">
                        Power up and cleanup the outdated, messy CRM data with
                        updated, single entry per contact or company, social
                        unique identifier in your CRM with CRM Cleanup API
                        credits.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div>
                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      CSV Enrichment
                      <img class="info-icon" src={Info} alt="Info" />
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span class="info-tooltip">
                        Power up and enrich your contact or company CSV with 40+
                        data attributes.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div> */}
                  <p></p>
                  <p></p>
                  <p></p>
                  <p></p>
                  <p></p>
                </div>
                <button className='show-details-btn' onClick={scrollToDetails}>
                  Show Comparison
                </button>
              </PlanBox2>

              <PlanBox3 className='starter'>
                <div className='plan-info'>
                  <div className='popular-label'>
                    <img src={StarIcon} className='star-icon' />
                    POPULAR
                  </div>
                  <p style={{ marginTop: '20px' }}>
                    <h3>PROSPECT</h3>
                    {/* <img class="info-icon" src={Info} alt="Info" />
                    <span class="info-tooltip-for-header">
                      To avail this plan, you need to have your account signed
                      up with corporate or domain emails address only.
                    </span> */}
                  </p>
                  <div className='Heading-content'>
                    <h2>$ 39</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>
                <div className='custom-hr-with-black'></div>
                <p className='header-p'>
                  Unlimited Email Credits
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With this plan, Users can unlock 3000 contacts per account
                    per day.
                  </span>
                </p>
                <p className='header-p'>1000 Export Credits /month</p>
                <p className='header-p'>
                  Unlimited Clodura Direct Dials Credits
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock unlimited direct dials from Clodura.AI's 12 million
                    direct dials database.
                  </span>
                </p>
                <p className='header-p'>
                  25 Partners Direct Dials Credits /month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock 25 direct dials per month from Clodura.AI's Search
                    Partners over 108M direct dials database.
                  </span>
                </p>
                <div className='custom-hr-with-black'></div>
                <Link href='/app/#/auth/register'>
                  <button className='btn'>Free Sign Up</button>
                </Link>
                <div className='planPlus'>Everything in Solo plus:</div>
                <div className='feature-list'>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Active Cadence
                    <span className='sub-font'> (30 Cadence Limit)</span>
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip-for-header'>
                      Cadences are used to create an automated or
                      semi-automated, repeatable process to get in contact with
                      prospects after you have their contact information. With
                      Cadence, you will only be able to create up to 30
                      Cadences.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Send 1500 emails/day
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      This is a limit of how many emails you can send through
                      Clodura.AI each day.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    AI Writer Credits
                    <span className='sub-font'> (10k Words per month)</span>
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip-for-section'>
                      Use Clodura.AI's AI Writer to generate subject lines,
                      email messages, and personalized openers custom-tailored
                      to your contacts.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Select 10000 Records at a time
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      You can select 10000 records at a time.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Advacned Search
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      For more detail about advanced search filters, please see
                      the Side-by-Side Showdown.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Buying Intent
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      With Buying Intent and Hiring Areas, you can filter your
                      company search to those who have expressed interest in
                      specific products, services or technology and hirings in
                      specific company.
                    </span>
                  </p>

                  {/* <p class="info-container">
                    <img class="xross-tick" src={BlueCheck} alt="Xross Image" />
                    Job changes
                    <img class="info-icon" src={Info} alt="Info" />
                    <span class="info-tooltip">
                      With Job Changes, you can update contacts when they change
                      jobs and allow you to see when your customers have changed
                      jobs to a new, relevant role within your territory.
                    </span>
                  </p> */}
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Search Saved
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Save your searches in case you want to come back to the
                      same search or want to save yourself the hassle of
                      reapplying the numerous filters repeatedly.
                    </span>
                  </p>

                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Upload CSV
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Import or upload your contact lists 02 CSVs per month of
                      contacts or suppression list by name, website or email
                      list.
                    </span>
                  </p>

                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Intergrate CRM
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Integrate Clodura.AI platform with any of the CRM. For
                      more detail about list of CRMs that could be integrated,
                      please see the Side-by-Side Showdown
                    </span>
                  </p>

                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Priority Email & Chat Support
                  </p>

                  {/* <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      Direct Dials Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span class="info-tooltip">
                        Direct dials credits from search partners.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div>

                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      Website Form Enrichment API Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span class="info-tooltip">
                        Power up the limit of website form enrichment and boost
                        up the lead gen forms on the websites.
                      </span>
                    </div>
                    <div style={{ marginTop: '2px' }} class="power-up-label">
                      + Power Up
                    </div>
                  </div>

                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      CRM Cleanup API Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span class="info-tooltip">
                        Power up and cleanup the outdated, messy CRM data with
                        updated, single entry per contact or company, social
                        unique identifier in your CRM with CRM Cleanup API
                        credits.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div>
                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      CSV Enrichment
                      <img class="info-icon" src={Info} alt="Info" />
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span class="info-tooltip">
                        Power up and enrich your contact or company CSV with 40+
                        data attributes.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div> */}
                  <p></p>
                  <p></p>
                </div>
                <button className='show-details-btn' onClick={scrollToDetails}>
                  Show Comparison
                </button>
              </PlanBox3>
              <PlanBox4>
                <div className='plan-info'>
                  <p style={{ marginTop: '20px' }}>
                    <h3>PROSPECT PRO</h3>
                    {/* <img class="info-icon" src={Info} alt="Info" />
                    <span class="info-tooltip">
                      To avail this plan, you need to have your account signed
                      up with corporate or domain emails address only.
                      <br />
                      Purpose of this plan is to solely support and encourage
                      the medium to large enterprise businesses community.
                    </span> */}
                  </p>
                  <div className='Heading-content'>
                    <h2>$ 69</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>
                <div className='custom-hr'></div>
                <p className='header-p'>
                  Unlimited Email Credits
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With this plan, Users can unlock 10000 contacts per user per
                    day.
                  </span>
                </p>

                <p className='header-p'>2000 Export Credits / month</p>

                <p className='header-p'>
                  Unlimited Clodura Direct Dials Credits
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock unlimited direct dials from Clodura.AI's 12 million
                    direct dials database.
                  </span>
                </p>

                <p className='header-p'>
                  50 Partners Direct Dials Credits / month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock 50 direct dials per month from Clodura.AI's Search
                    Partners over 108M direct dials database.
                  </span>
                </p>

                <div className='custom-hr'></div>
                <Link href='/app/#/auth/register'>
                  <button className='btn'>Free Sign Up</button>
                </Link>
                <div className='planPlus'>Everything in Prospect plus:</div>
                <div className='feature-list'>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    No Cadence Limit
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Cadences are used to create an automated or
                      semi-automated, repeatable process to get in contact with
                      prospects after you have their contact information. With
                      Cadence, you will be able to create unlimited Cadences.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Send Unlimited emails/day
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      This is a limit of how many emails you can send through
                      Clodura.AI each day.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Soft Leads Identification
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      This identifies the soft leads from your campaigns based
                      on various parameters like email read/open, link clicks,
                      reply.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    AI Writer Credits
                    <span className='sub-font'>
                      {' '}
                      (Unlimited Words per month)
                    </span>
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip-for-section'>
                      Use Clodura.AI's AI Writer to generate subject lines,
                      email messages, and personalized openers custom-tailored
                      to your contacts.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Suppressions List
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Suppression list is where you upload lists of websites,
                      emails, and contact names to automatically obtain
                      additional details.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Cadence Analytics
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      This allows you to track performance of your campaigns and
                      emails.
                      <br />
                      For more detail about basic analytics, please see the
                      Side-by-Side Showdown.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    All Email Service Provider Integration
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Integrate your Clodura.AI account with any email service
                      providers like Gmail, Outlook, and other.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Upload CSV
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Import or upload your contact lists unlimited CSVs per
                      month of contacts or suppression list by name, website or
                      email list.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    CSV Enrichment
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Upload your contact or company data CSV and get it
                      enriched with 40+ data attributes.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Team Features
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Add or remove the user from your account.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Team Dashboard
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      This will help you to track the performance of your each
                      team member based on how many contacts unlocked, direct
                      dials unlocked, email sent, their emails, campaign
                      performance and lot more.
                    </span>
                  </p>

                  {/* <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      Direct Dials Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span class="info-tooltip">
                        Direct dials credits from search partners.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div>

                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      Website Form Enrichment API Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span class="info-tooltip">
                        Power up the limit of website form enrichment and boost
                        up the lead gen forms on the websites.
                      </span>
                    </div>
                    <div style={{ marginTop: '2px' }} class="power-up-label">
                      + Power Up
                    </div>
                  </div>

                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      CRM Cleanup API Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span class="info-tooltip">
                        Power up and cleanup the outdated, messy CRM data with
                        updated, single entry per contact or company, social
                        unique identifier in your CRM with CRM Cleanup API
                        credits.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div>
                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      CSV Enrichment
                      <img class="info-icon" src={Info} alt="Info" />
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span class="info-tooltip">
                        Power up and enrich your contact or company CSV with 40+
                        data attributes.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div> */}
                  <p></p>
                </div>
                <button className='show-details-btn' onClick={scrollToDetails}>
                  Show Comparison
                </button>
              </PlanBox4>
            </>
          )}
          {yearlyUSD && (
            <>
              <PlanBox>
                <div className='plan-info'>
                  <p style={{ marginTop: '20px' }}>
                    <h3>FREE FOREVER</h3>
                    <span className='sub-font'>&#160;</span>
                  </p>
                  <div
                    className='Heading-content'
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <h2>$ 0</h2>
                    <span className='sub-font-head'>
                      No Credit Card Required
                    </span>
                  </div>
                  <br />
                </div>
                <div className='custom-hr'></div>
                <p className='header-p'>
                  Unlimited Email Credits
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Clodura.AI’s Free Forever plan operate under a Fair Usage
                    Policy in order to prevent potential abuse. For non-paying
                    customers using an email address with a valid and verified
                    (by Clodura.AI in its sole discretion) corporate domain,
                    Clodura.AI has implemented a credit limit of 10,000 credits
                    per account per month to prevent abuse. Therefore, we
                    recommend that you use an email address with a valid
                    corporate domain in connection with your Clodura.AI account.
                    Non-paying customers using an email address with generic
                    email address have a lower credit limit of 100 credits per
                    account per month.
                  </span>
                </p>
                <p className='header-p'>100 Export Credits /month</p>
                <p className='header-p'>
                  12M Clodura Direct Dials Credits / month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock direct dials from Clodura.AI's 12 million direct
                    dials database for FREE.
                  </span>
                </p>
                <p className='header-p'>
                  5 Partners Direct Dials Credits / month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock 5 direct dials per month from Clodura.AI's Search
                    Partners over 108M direct dials database for FREE. These
                    credits are available to non-paying users with valid
                    corporate email address only.
                  </span>
                </p>
                {/* <p className='header-p'></p> */}
                <div className='custom-hr'></div>
                <Link href='/app/#/auth/register'>
                  <button className='btn'>Free Sign Up</button>
                </Link>
                <div className='planPlus'></div>
                <div className='feature-list'>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Active Cadence
                    <span className='sub-font'> (2 Cadence Limit)</span>
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip-for-header'>
                      Cadences are used to create an automated or
                      semi-automated, repeatable process to get in contact with
                      prospects after you have their contact information. With
                      Cadence, you will only be able to create up to 2 Cadences.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Send 200 emails/day
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      This is a limit of how many emails you can send through
                      Clodura.AI each day.
                    </span>
                  </p>

                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    AI Writer Credits
                    <span className='sub-font'> (1200 Words per month)</span>
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip-for-section'>
                      Use Clodura.AI's AI Writer to generate subject lines,
                      email messages, and personalized openers custom-tailored
                      to your contacts.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    05 Company Org Charts
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Discover 05 companies org charts/reporting structure per
                      month from 18M+ companies.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    View 05 Company Reports
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Number of company reports you can view per month
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Basic Search <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      For more detail about basic search filters, please see the
                      Side-by-Side Showdown.
                    </span>
                  </p>

                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Chrome Extension
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      The Chrome Extension can be used to find email addresses,
                      phone numbers, funding & revenue data, technology data,
                      and more directly from LinkedIn or any website on the web.
                      Additionally, if you have your mailbox linked, you can
                      send emails and make phone calls directly from LinkedIn or
                      any website on the web.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Basic Analytics
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      This allows you to track performance of your campaigns and
                      emails with basic details. For more detail about basic
                      analytics, please see the Side-by-Side Showdown.
                    </span>
                  </p>
                  {/* <p class="info-container">
                    <img class="xross-tick" src={BlueCheck} alt="Xross Image" />
                    API Access
                    <img class="info-icon" src={Info} alt="Info" />
                    <span class="info-tooltip">
                      Our API can be used to enrich the data and integrations
                      with your existing CRM with Clodura's data.
                    </span>
                  </p> */}
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Gmail & Outook Integration
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Integrate your Clodura.AI account with email service
                      providers like Gmail, Outlook.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Email Setup
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Connect or setup your email from which you want to reach
                      out to your prospect list
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Preferences
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      With this, you can set the contact and company search i.e.
                      Buyers Persona, theme of the Clodura.AI account,
                      Notification, Passwords, App Locks.
                    </span>
                  </p>
                  {/* <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      Direct Dials Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span class="info-tooltip">
                        Direct dials credits from search partners.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div>

                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      Website Form Enrichment API Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span class="info-tooltip">
                        Power up the limit of website form enrichment and boost
                        up the lead gen forms on the websites.
                      </span>
                    </div>
                    <div style={{ marginTop: '2px' }} class="power-up-label">
                      + Power Up
                    </div>
                  </div>

                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      CRM Cleanup API Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span class="info-tooltip">
                        Power up and cleanup the outdated, messy CRM data with
                        updated, single entry per contact or company, social
                        unique identifier in your CRM with CRM Cleanup API
                        credits.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div>
                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      CSV Enrichment
                      <img class="info-icon" src={Info} alt="Info" />
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span class="info-tooltip">
                        Power up and enrich your contact or company CSV with 40+
                        data attributes.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div> */}
                  <p></p>
                </div>
                <button className='show-details-btn' onClick={scrollToDetails}>
                  Show Comparison
                </button>
              </PlanBox>
              <PlanBox2 className='box-with-upside-shadow'>
                <div className='plan-info'>
                  <p style={{ marginTop: '20px' }}>
                    <h3>SOLO</h3>
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip-for-header'>
                      To avail this plan, you need to have your account signed
                      up with one of the generic email address like Gmail,
                      yahoo, and other only. User with Corporate or domain
                      emails address won't be able to avail this plan.
                      <b />
                      Purpose of this plan is to solely support and encourage
                      the Freelancer, Solo Entrepreneurs community.
                    </span>
                  </p>
                  <div className='Heading-content'>
                    <h2>$ 8</h2>
                    <small>per month</small>
                  </div>
                </div>
                <div className='custom-hr'></div>
                <p className='header-p'>
                  12000 Email credits/year per account
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With this, user can unlock max 100 contacts per day.
                  </span>
                </p>
                <p className='header-p'>12000 Export Credits / year</p>
                <p className='header-p'>
                  12M Clodura Direct Dials Credits / month
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock direct dials from Clodura.AI's 12 million direct
                    dials database.
                  </span>
                </p>
                <p className='header-p'>
                  120 Partners Direct Dials Credits / year
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock 120 direct dials per year from Clodura.AI's Search
                    Partners over 108M direct dials database.
                  </span>
                </p>
                <div className='custom-hr'></div>
                <Link href='/app/#/auth/register'>
                  <button className='btn'>Free Sign Up</button>
                </Link>
                <div className='planPlus'>Everything in Free Forever Plus:</div>
                <div className='feature-list'>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Active Cadence
                    <span className='sub-font'> (10 Cadence Limit)</span>
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip-for-header'>
                      Cadences are used to create an automated or
                      semi-automated, repeatable process to get in contact with
                      prospects after you have their contact information. With
                      Cadence, you will only be able to create up to 10
                      Cadences.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Send 500 emails/day
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      This is a limit of how many emails you can send through
                      Clodura.AI each day.
                    </span>
                  </p>

                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    AI Writer Credits
                    <span className='sub-font'> (2000 Words per month)</span>
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip-for-section'>
                      Use Clodura.AI's AI Writer to generate subject lines,
                      email messages, and personalized openers custom-tailored
                      to your contacts.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Select 100 Records at a time
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      You can select 100 records at a time, instead of standard
                      01.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Calling Disposition
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Call disposition is a label representing the outcome of an
                      inbound or outbound call.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Billing <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      With this, you can manage your billing details and can
                      generate the invoices to reimburse or maintain billing.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Priority Email Support Only
                  </p>

                  {/* <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      Direct Dials Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span class="info-tooltip">
                        Direct dials credits from search partners.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div>

                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      Website Form Enrichment API Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span class="info-tooltip">
                        Power up the limit of website form enrichment and boost
                        up the lead gen forms on the websites.
                      </span>
                    </div>
                    <div style={{ marginTop: '2px' }} class="power-up-label">
                      + Power Up
                    </div>
                  </div>

                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      CRM Cleanup API Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span class="info-tooltip">
                        Power up and cleanup the outdated, messy CRM data with
                        updated, single entry per contact or company, social
                        unique identifier in your CRM with CRM Cleanup API
                        credits.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div>
                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      CSV Enrichment
                      <img class="info-icon" src={Info} alt="Info" />
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span class="info-tooltip">
                        Power up and enrich your contact or company CSV with 40+
                        data attributes.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div> */}
                  <p></p>
                  <p></p>
                  <p></p>
                  <p></p>
                  <p></p>
                </div>
                <button className='show-details-btn' onClick={scrollToDetails}>
                  Show Comparison
                </button>
              </PlanBox2>
              <PlanBox3 className='starter'>
                <div className='plan-info'>
                  <div className='popular-label'>
                    <img src={StarIcon} className='star-icon' />
                    POPULAR
                  </div>
                  <p c style={{ marginTop: '20px' }}>
                    <h3>PROSPECT</h3>
                    {/* <img class="info-icon" src={Info} alt="Info" />
                    <span class="info-tooltip-for-header">
                      To avail this plan, you need to have your account signed
                      up with corporate or domain emails address only.
                    </span> */}
                  </p>

                  <div className='Heading-content'>
                    <h2>$ 33</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>
                <div className='custom-hr-with-black'></div>
                <p className='header-p'>
                  Unlimited Email Credits
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With this plan, Users can unlock 3000 contacts per account
                    per day.
                  </span>
                </p>
                <p className='header-p'>12000 Export Credits /year</p>
                <p className='header-p'>
                  Unlimited Clodura Direct Dials Credits
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With this plan, Users can unlock 3000 contacts per account
                    per day.
                  </span>
                </p>

                <p className='header-p'>
                  300 Partners Direct Dials Credits / year
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock 300 direct dials per year from Clodura.AI's Search
                    Partners over 108M direct dials database.
                  </span>
                </p>

                <div className='custom-hr-with-black'></div>
                <Link href='/app/#/auth/register'>
                  <button className='btn'>Free Sign Up</button>
                </Link>
                <div className='planPlus'>Everything in Solo plus:</div>
                <div className='feature-list'>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Active Cadence
                    <span className='sub-font'> (30 Cadence Limit)</span>
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip-for-header'>
                      Cadences are used to create an automated or
                      semi-automated, repeatable process to get in contact with
                      prospects after you have their contact information. With
                      Cadence, you will only be able to create up to 30
                      Cadences.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Send 1500 emails/day
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      This is a limit of how many emails you can send through
                      Clodura.AI each day.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    AI Writer Credits
                    <span className='sub-font'> (10000 Words per month)</span>
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip-for-section'>
                      Use Clodura.AI's AI Writer to generate subject lines,
                      email messages, and personalized openers custom-tailored
                      to your contacts.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Select 10000 Records at a time
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      You can select 10000 records at a time.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Advacned Search
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      For more detail about advanced search filters, please see
                      the Side-by-Side Showdown.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Buying Intent
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      With Buying Intent and Hiring Areas, you can filter your
                      company search to those who have expressed interest in
                      specific products, services or technology and hirings in
                      specific company.
                    </span>
                  </p>

                  {/* <p class="info-container">
                    <img class="xross-tick" src={BlueCheck} alt="Xross Image" />
                    Job changes
                    <img class="info-icon" src={Info} alt="Info" />
                    <span class="info-tooltip">
                      With Job Changes, you can update contacts when they change
                      jobs and allow you to see when your customers have changed
                      jobs to a new, relevant role within your territory.
                    </span>
                  </p> */}

                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Search Saved
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Save your searches in case you want to come back to the
                      same search or want to save yourself the hassle of
                      reapplying the numerous filters repeatedly.
                    </span>
                  </p>

                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Upload CSV
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Import or upload your contact lists 02 CSVs per month of
                      contacts or suppression list by name, website or email
                      list.
                    </span>
                  </p>

                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Intergrate CRM
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Integrate Clodura.AI platform with any of the CRM. For
                      more detail about list of CRMs that could be integrated,
                      please see the Side-by-Side Showdown
                    </span>
                  </p>

                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Priority Email & Chat Support
                  </p>

                  {/* <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      Direct Dials Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span class="info-tooltip">
                        Direct dials credits from search partners.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div>

                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      Website Form Enrichment API Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span class="info-tooltip">
                        Power up the limit of website form enrichment and boost
                        up the lead gen forms on the websites.
                      </span>
                    </div>
                    <div style={{ marginTop: '2px' }} class="power-up-label">
                      + Power Up
                    </div>
                  </div>

                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      CRM Cleanup API Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span class="info-tooltip">
                        Power up and cleanup the outdated, messy CRM data with
                        updated, single entry per contact or company, social
                        unique identifier in your CRM with CRM Cleanup API
                        credits.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div>
                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      CSV Enrichment
                      <img class="info-icon" src={Info} alt="Info" />
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span class="info-tooltip">
                        Power up and enrich your contact or company CSV with 40+
                        data attributes.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div> */}
                  <p></p>
                  <p></p>
                </div>
                <button className='show-details-btn' onClick={scrollToDetails}>
                  Show Comparison
                </button>
              </PlanBox3>
              <PlanBox4>
                <div className='plan-info'>
                  <p style={{ marginTop: '20px' }}>
                    <h3>PROSPECT PRO</h3>
                    {/* <img class="info-icon" src={Info} alt="Info" />
                    <span class="info-tooltip">
                      To avail this plan, you need to have your account signed
                      up with corporate or domain emails address only. Purpose
                      of this plan is to solely support and encourage the medium
                      to large enterprise businesses community.
                    </span> */}
                  </p>
                  <div className='Heading-content'>
                    <h2>$ 58</h2>
                    <small>
                      per user <br /> per month
                    </small>
                  </div>
                </div>
                <div className='custom-hr'></div>
                <p className='header-p'>
                  Unlimited Email Credits
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    With this plan, Users can unlock 10000 contacts per user per
                    day.
                  </span>
                </p>
                <p className='header-p'>24000 Export Credits /year</p>
                <p className='header-p'>
                  Unlimited Clodura Direct Dials Credits.
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock unlimited direct dials from Clodura.AI's 12 million
                    direct dials database.
                  </span>
                </p>

                <p className='header-p'>
                  600 Partners Direct Dials Credits / year
                  <img class='info-icon' src={Info} alt='Info' />
                  <span class='info-tooltip'>
                    Unlock 600 direct dials per year from Clodura.AI's Search
                    Partners over 108M direct dials database.
                  </span>
                </p>
                <div className='custom-hr'></div>
                <Link href='/app/#/auth/register'>
                  <button className='btn'>Free Sign Up</button>
                </Link>
                <div className='planPlus'>Everything in Prospect plus:</div>
                <div className='feature-list'>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    No Cadence Limit
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Cadences are used to create an automated or
                      semi-automated, repeatable process to get in contact with
                      prospects after you have their contact information. With
                      Cadence, you will be able to create unlimited Cadences.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Send Unlimited emails/day
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      This is a limit of how many emails you can send through
                      Clodura.AI each day.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Soft Leads Identification
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      This identifies the soft leads from your campaigns based
                      on various parameters like email read/open, link clicks,
                      reply.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    AI Writer Credits
                    <span className='sub-font'>
                      {' '}
                      (Unlimited Words per month)
                    </span>
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip-for-section'>
                      Use Clodura.AI's AI Writer to generate subject lines,
                      email messages, and personalized openers custom-tailored
                      to your contacts.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Suppressions List
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Suppression list is where you upload lists of websites,
                      emails, and contact names to automatically obtain
                      additional details.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Cadence Analytics
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      This allows you to track performance of your campaigns and
                      emails.
                      <br />
                      For more detail about basic analytics, please see the
                      Side-by-Side Showdown.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    All Email Service Provider Integration
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Integrate your Clodura.AI account with any email service
                      providers like Gmail, Outlook, and other.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Upload CSV
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Import or upload your contact lists unlimited CSVs per
                      month of contacts or suppression list by name, website or
                      email list.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    CSV Enrichment
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Upload your contact or company data CSV and get it
                      enriched with 40+ data attributes.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Team Features
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      Add or remove the user from your account.
                    </span>
                  </p>
                  <p class='info-container'>
                    <img class='xross-tick' src={BlueCheck} alt='Xross Image' />
                    Team Dashboard
                    <img class='info-icon' src={Info} alt='Info' />
                    <span class='info-tooltip'>
                      This will help you to track the performance of your each
                      team member based on how many contacts unlocked, direct
                      dials unlocked, email sent, their emails, campaign
                      performance and lot more.
                    </span>
                  </p>
                  {/* 
                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      Direct Dials Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span class="info-tooltip">
                        Direct dials credits from search partners.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div>

                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      Website Form Enrichment API Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span class="info-tooltip">
                        Power up the limit of website form enrichment and boost
                        up the lead gen forms on the websites.
                      </span>
                    </div>
                    <div style={{ marginTop: '2px' }} class="power-up-label">
                      + Power Up
                    </div>
                  </div>

                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      CRM Cleanup API Credits
                      <img class="info-icon" src={Info} alt="Info" />
                      <span class="info-tooltip">
                        Power up and cleanup the outdated, messy CRM data with
                        updated, single entry per contact or company, social
                        unique identifier in your CRM with CRM Cleanup API
                        credits.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div>
                  <div class="info-container">
                    <div class="paragraph-content">
                      <img
                        class="xross-tick"
                        src={BlueCheck}
                        alt="Xross Image"
                      />
                      CSV Enrichment
                      <img class="info-icon" src={Info} alt="Info" />
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span> &#160;</span>
                      <span class="info-tooltip">
                        Power up and enrich your contact or company CSV with 40+
                        data attributes.
                      </span>
                    </div>
                    <div class="power-up-label">+ Power Up</div>
                  </div> */}
                  <p></p>
                </div>
                <button className='show-details-btn' onClick={scrollToDetails}>
                  Show Comparison
                </button>
              </PlanBox4>
            </>
          )}
        </div>
      </PlansWrapper>
      <G2Slider />
      <div ref={detailsRef} id='details'>
        {monthlyUSD ? <MonthPlanePage /> : <YearPlanePage />}
      </div>
    </>
  );
};

Plans.propTypes = {
  button: PropTypes.object,
};

Plans.defaultProps = {
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'tertiaryWithBg',
    minHeight: 'auto',
    height: '40px',
  },
};

export default Plans;
